import * as types from "./actionsTypes";
import * as deviceStateApi from "../api/deviceStateApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export const loadDeviceState = (auth, IMEI) => async (dispatch) => {
  dispatch(beginApiCall());

  deviceStateApi
    .getDeviceState(auth, IMEI)
    .then((state) => {
      dispatch({
        type: types.LOAD_DEVICE_STATE_SUCCESS,
        payload: {
          deviceState: state,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
