import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Router
import { Link } from "react-router-dom";

// Redux
import { useDispatch, connect } from "react-redux";
import { loadCustomerInfo } from "../../actions/customerActions";
import { getDevicesByCustomerId } from "../../actions/deviceCustomerActions";
import {
  loadCustomerFrontendUsers,
  saveNewUser,
  removeUser,
  loadRoles,
} from "../../actions/userActions";
import { loadConfigTemplates } from "../../actions/configTemplateActions";

// Components
import Spinner from "../common/Spinner";
import CreateUserForCustomerModal from "./components/CreateUserForCustomerModal";
import UserTable from "../admin/UserTable";
import ConfigurationTab from "./components/ConfigurationTab";

// Styling
import { Card, Tabs, Tab } from "react-bootstrap";
import "./CustomerInfo.css";

const CustomerInfoPage = ({
  auth,
  roles,
  customer,
  customerDevices,
  users,
  isLoading,
  validName,
  ...props
}) => {
  const dispatch = useDispatch();

  const [showUser, setShowUser] = useState(false);
  const [newUser, setNewUser] = useState({
    customerId: props.match.params.id,
    username: "",
    email: "",
    password: "",
    roles: ["customer"],
  });

  const [key, setKey] = useState("devices");

  useEffect(() => {
    loadCustomerDetails();
    loadCustomerUsers();
    loadCustomerDevices();
    loadCustomerConfigTemplates();
    dispatch(loadRoles(auth));
  }, []);

  const loadCustomerDetails = () => {
    dispatch(loadCustomerInfo(auth, props.match.params.id));
  };

  const loadCustomerUsers = () => {
    dispatch(loadCustomerFrontendUsers(auth, props.match.params.id));
  };

  const loadCustomerDevices = () => {
    dispatch(getDevicesByCustomerId(auth, props.match.params.id));
  };

  const loadCustomerConfigTemplates = () => {
    dispatch(loadConfigTemplates(auth, props.match.params.id));
  };

  const saveUser = (e) => {
    e.preventDefault();

    if (customer.isReseller) {
      newUser.roles.push("reseller");
    }

    dispatch(saveNewUser(auth, newUser));
    setShowUser(false);
    setNewUser({
      customerId: props.match.params.id,
      username: "",
      email: "",
      password: "",
      roles: ["customer"],
    });
    reloadUsers();
  };

  const removeUserHandler = (id) => {
    dispatch(removeUser(auth, id));
    reloadUsers();
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const reloadUsers = async () => {
    await delay(1000);
    loadCustomerUsers();
  };

  return (
    <>
      {customer === null || isLoading ? (
        <Spinner />
      ) : (
        <Card className="card-style device">
          <Card.Header>
            <h1 className="title">{customer.name}</h1>
          </Card.Header>

          <Card.Body>
            <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
              <Tab eventKey="devices" title="Devices">
                <p style={{ float: "right" }}>
                  <strong>Results: </strong> {customerDevices.length}
                </p>
                <br />
                <br />
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>IMEI</th>
                      <th>SN</th>
                      <th>Start date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerDevices.length === 0 ? (
                      <tr>
                        <td colSpan="14" style={{ textAlign: "center" }}>
                          No devices found
                        </td>
                      </tr>
                    ) : (
                      customerDevices.map((device) => {
                        return (
                          <tr key={device._id} className="table-row">
                            <td>
                              <Link to={"/device/" + device._id}>
                                {device.IMEI}
                              </Link>
                            </td>
                            <td>{device.SerialNumber}</td>
                            <td>{device.relation.start}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </Tab>
              <Tab eventKey="users" title="Users">
                <CreateUserForCustomerModal
                  auth={auth}
                  validName={validName}
                  show={showUser}
                  setShow={setShowUser}
                  newUser={newUser}
                  setNewUser={setNewUser}
                  saveUser={saveUser}
                />
                <br />
                <br />
                <UserTable
                  auth={auth}
                  users={users}
                  roles={roles}
                  removeUserHandler={removeUserHandler}
                  customer={true}
                />
              </Tab>
              {auth.user.customerId == null && (
                <Tab eventKey="configurations" title="Configurations">
                  <ConfigurationTab />
                </Tab>
              )}
            </Tabs>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

CustomerInfoPage.propTypes = {
  auth: PropTypes.object.isRequired,
  customer: PropTypes.object,
  customerDevices: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  validName: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customer: state.customerDetails.customer,
    customerDevices: state.customerDetails.devices,
    users: state.customerDetails.users,
    roles: state.users.roles,
    isLoading: state.customerDetails.isLoading,
    validName: state.inputValidation.validName,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfoPage);
