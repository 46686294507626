import * as types from "./actionsTypes";
import * as deviceEndpointApi from "../api/deviceEndpointApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export const loadEndpointsDevice = (auth, IMEI) => async (dispatch) => {
  dispatch(beginApiCall());

  dispatch({
    type: "LOADING_DEVICE",
    payload: {
      isLoading: true,
    },
  });

  deviceEndpointApi
    .getEndpointsforDevice(auth, IMEI)
    .then((endpoints) => {
      dispatch({
        type: types.LOAD_DEVICE_ENDPOINTS_SUCCESS,
        payload: {
          endpoints: endpoints,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
