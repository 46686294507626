const user = JSON.parse(localStorage.getItem("user"));

const initialUserState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: { accessToken: "" } };

const initialDeviceDetailState = {
  isLoading: false,
  device: null,
  endpoints: [],
  state: [],
  modemRequests: [],
  rawMessages: [],
  customerHistory: [],
};

const initialUsersState = {
  users: [],
  roles: [],
};

const inputValidationState = {
  validName: true,
  validCustomerName: true,
  validCustomerEmail: true,
};

export default {
  devices: [],
  customers: [],
  endpoints: [],
  rawMessages: [],
  apiCallsInProgress: 0,
  auth: initialUserState,
  message: {},
  deviceDetails: initialDeviceDetailState,
  logs: [],
  users: initialUsersState,
  inputValidation: inputValidationState,
  configTemplates: [],
};
