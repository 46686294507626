import * as types from '../actions/actionsTypes';
import initialState from './initialState';

function actionTypeEndsInSucces(type) {
    return type.substring(type.length - 8) === "_SUCCESS";
}

export default function apiCallStatusReducer(
    state = initialState.apiCallsInProgress,
    action
) {
    if (action.type == types.BEGIN_API_CALL) {
        return state + 1;
    } else if (action.type == types.API_CALL_ERROR || actionTypeEndsInSucces(action.type)) {
        return state - 1;
    } else if (action.type == types.LOGOUT) {
        return initialState.apiCallsInProgress;
    }
    return state;
}

