import * as types from "../actions/actionsTypes";

const initState = {
  customer: null,
  devices: [],
  users: [],
  configTemplates: [],
  isLoading: false,
};

const customerDetailsReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOADING_CUSTOMER_DETAILS":
      return {
        ...state,
        isLoading: true,
      };
    case types.LOAD_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        customer: action.payload.customer,
        isLoading: false,
      };

    case types.LOAD_CUSTOMER_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        isLoading: false,
      };

    case types.LOAD_CUSTOMER_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload.devices,
        isLoading: false,
      };
    case types.LOAD_CONFIG_TEMPLATES_SUCCESS:
      return {
        ...state,
        configTemplates: action.payload.configTemplates,
        isLoading: false,
      };
    case types.REMOVE_CONFIG_TEMPLATE_SUCCESS:
      return {
        ...state,
        configTemplates: state.configTemplates.filter(
          (configTemplate) =>
            configTemplate._id != action.payload.configTemplate
        ),
      };
    case types.SAVE_CONFIG_TEMPLATE_SUCCESS:
      return {
        ...state,
        configTemplates: [
          ...state.configTemplates,
          action.payload.configTemplate,
        ],
      };

    case types.SAVE_CUSTOMER_DEVICES_SUCCESS:
      return {
        ...state,
        devices: [...state.devices, action.payload.devices],
      };

    case types.REMOVE_CUSTOMER_DEVICES_SUCCESS:
      return {
        ...state,
        devices: state.devices.filter(
          (device) => device._id != action.payload.devices
        ),
      };

    default:
      return { ...state };
  }
};

export default customerDetailsReducer;
