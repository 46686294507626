import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { connect, useDispatch } from "react-redux";
import { loadLogs } from "../../actions/logsAction";

// Components
import LogList from "./LogList";
import Spinner from "../common/Spinner";

// Styling
import "./Log.css";
import { Button, Card, Form } from "react-bootstrap";

const LogPage = ({ auth, logs, apiCallsInProgress }) => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    dispatch(loadLogs(auth));
  }, []);

  const refreshData = () => {
    dispatch(loadLogs(auth));
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const result = logs.filter((logs) =>
      logs[filter].toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    setSearchResult(result);
  }, [searchTerm, logs, filter]);

  const [filter, setFilter] = useState("Text");

  const filterHandler = (e) => {
    setFilter(e.target.value);
  };

  return (
    <>
      <h2 className="title">Log</h2>
      <div className="log">
        <Card className="search">
          <div className="search">
            <Form.Control
              className="search-select-messages"
              as="select"
              name="customer"
              value={filter}
              onChange={filterHandler}
            >
              <option key={"Text"} value={"Text"}>
                Log
              </option>
              <option key={"Level"} value={"Level"}>
                Level
              </option>
              <option key={"Timestamp"} value={"Timestamp"}>
                TimeStamp
              </option>
            </Form.Control>
            <input
              className="search-input"
              type="text"
              placeholder="Search log"
              value={searchTerm}
              onChange={handleChange}
            />
            <div className="result">
              <b>Results : </b>
              {searchResult.length > 0
                ? " " + searchResult.length
                : " " + logs.length}
            </div>
            <Button
              className="refresh-border"
              variant="light"
              onClick={refreshData}
            >
              ⟳
            </Button>
          </div>
        </Card>
      </div>
      <br />
      <Card>
        {(logs.length === 0) | (apiCallsInProgress > 0) ? (
          <Spinner />
        ) : (
          <>
            {searchTerm.length === 0 ? (
              <LogList logs={logs} />
            ) : (
              <LogList logs={searchResult} />
            )}
          </>
        )}
      </Card>
    </>
  );
};

LogPage.propTypes = {
  auth: PropTypes.object.isRequired,
  logs: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    logs: state.logs,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LogPage);
