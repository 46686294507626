import * as types from "../actions/actionsTypes";

const initState = {
  logs: 0,
  endpoints: 0,
  rawMessages: 0,
  lastMsg: 0,
  msgAge: 0,
  deviceCount: 0,
  weekly: 0,
};

const dashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case types.LOAD_DASHBOARD_SUCCESS:
      return {
        ...state,
        logs: action.payload.dashboard.Logs,
        endpoints: action.payload.dashboard.Endpoints,
        rawMessages: action.payload.dashboard.rawMessages,
        lastMsg: action.payload.dashboard.Lastmsg,
        msgAge: action.payload.dashboard.msgAge,
        deviceCount: action.payload.dashboard.devicecount,
        weekly: action.payload.dashboard.weekly,
      };

    default:
      return { ...state };
  }
};

export default dashboardReducer;
