import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Styling
import { Form, Button, Modal } from "react-bootstrap";

const ChangeCustomerModal = ({
  auth,
  show,
  setShow,
  customers,
  selected,
  setCustomer,
  customer,
  saveDevices,
}) => {
  const [disabled, setDisabled] = useState(true);
  const inputHandler = (e) => {
    if (e.target.value == "null" && auth.user.customerId != null) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setCustomer(e.target.value);
  };

  useEffect(() => {
    if (auth.user.customerId != null) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
    setCustomer("");
  };

  return (
    <>
      <div className="set-customer-modal">
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="text-center"
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>You are about to change {selected.length} device(s).</p>
            <Form className="text-left">
              <Form.Row>
                <Form.Label>Customers</Form.Label>
                <Form.Control
                  as="select"
                  name="customer"
                  value={customer}
                  onChange={inputHandler}
                >
                  <option key={"null"} value={"null"}>
                    --
                  </option>
                  {customers.map((customer) => {
                    return (
                      <option key={customer._id} value={customer._id}>
                        {customer.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button disabled={disabled} onClick={saveDevices}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

ChangeCustomerModal.propTypes = {
  auth: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  customers: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  setCustomer: PropTypes.func.isRequired,
  customer: PropTypes.string.isRequired,
  saveDevices: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customers: state.customers,
  };
}
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeCustomerModal);
