import React from "react";

import { Card } from "react-bootstrap";

const FirmwarePage = () => {
  return (
    <div className="firmware">
      <h2 className="title">Firmware</h2>
      <Card>
        <Card.Header>Firmware</Card.Header>
        <Card.Body>This page is currently under construction.</Card.Body>
        <Card.Footer>See you soon!</Card.Footer>
      </Card>
    </div>
  );
};

export default FirmwarePage;
