import React from "react";
import PropTypes from "prop-types";

// Styling
import { Modal, Button, Form } from "react-bootstrap";

const RequestModal = ({
  show,
  onCancel,
  request,
  onRemove,
  onSave,
  setRequest,
  isLoading,
}) => {
  return (
    <>
      {!isLoading ? (
        <Modal
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={onCancel}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                IMEI: {request.IMEI}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label column sm="2">
                <h6>Command: </h6>
              </Form.Label>
              <Form.Control
                defaultValue={request.Command}
                onChange={(e) =>
                  setRequest({
                    _id: request._id,
                    IMEI: request.IMEI,
                    Command: e.target.value,
                    Executed: request.Executed,
                    CommandType: "Web",
                  })
                }
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onCancel}>Cancel</Button>
              <Button
                onClick={onRemove}
                disabled={
                  request._id == null || request.Executed != null ? true : false
                }
                variant="danger"
                type="submit"
              >
                Remove
              </Button>
              <Button
                onClick={onSave}
                disabled={
                  request.Command.length < 3 || request.Executed != null
                    ? true
                    : false
                }
                variant="success"
                type="submit"
              >
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

RequestModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RequestModal;
