import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Redux
import { useDispatch, connect } from "react-redux";
import { fetchCustomers, saveCustomer } from "../../actions/customerActions";

// Components
import CustomerList from "./components/CustomerList";
import CreateCustomer from "./components/CreateCustomer";

import { Card, Button, Form } from "react-bootstrap";

import "./CustomerPage.css";

const CustomersPage = ({
  auth,
  customers,
  validCustomerName,
  validCustomerEmail,
}) => {
  const dispatch = useDispatch();
  const customerList = [...customers];
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [show, setShow] = useState(false);
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    country: "Netherlands",
    start: moment().format("YYYY-MM-DD"),
    isReseller: false,
    reseller_id: auth.user.customerId != null ? auth.user.customerId : null,
    features: [],
  });

  useEffect(() => {
    dispatch(fetchCustomers(auth));
  }, []);

  const refreshData = () => {
    setSearchTerm("");
    dispatch(fetchCustomers(auth));
  };

  useEffect(() => {
    if (searchTerm === "") {
      setSearchResult(customerList);
    } else {
      const result = customerList.filter((customerList) =>
        customerList[filter]
          .toLowerCase()
          .includes(searchTerm.toLocaleLowerCase())
      );
      setSearchResult(result);
    }
  }, [searchTerm, customerList, filter]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const saveCustomerHandler = () => {
    if (!validCustomerName || !validCustomerEmail) {
      alert("Please use a different Name/E-mail adress");
    } else {
      dispatch(saveCustomer(auth, customer));
      setShow(false);
      setCustomer({
        name: "",
        email: "",
        country: "Netherlands",
        start: moment().format("YYYY-MM-DD"),
        isReseller: false,
        reseller_id: auth.user.customerId != null ? auth.user.customerId : null,
        features: [],
      });
    }
  };

  const [filter, setFilter] = useState("name");

  const filterHandler = (e) => {
    setFilter(e.target.value);
  };

  return (
    <div className="customers">
      <h2 className="title">Customers</h2>
      <Card>
        <div className="search">
          <Form.Control
            className="search-select-messages"
            as="select"
            name="customer"
            value={filter}
            onChange={filterHandler}
          >
            <option key={"name"} value={"name"}>
              Name
            </option>
            <option key={"email"} value={"email"}>
              Email
            </option>
            <option key={"country"} value={"country"}>
              Country
            </option>
          </Form.Control>
          <input
            className="search-input"
            type="text"
            placeholder="Search customers"
            value={searchTerm}
            onChange={handleChange}
          />
          <div className="result">
            <b>Results : </b>
            {searchResult.length > 0
              ? " " + searchResult.length
              : " " + customerList.length}
          </div>
          <Button
            className="refresh-border"
            variant="light"
            onClick={refreshData}
          >
            ⟳
          </Button>
        </div>
      </Card>
      <div className="create-customer-div">
        <CreateCustomer
          auth={auth}
          show={show}
          setShow={setShow}
          customer={customer}
          setCustomer={setCustomer}
          saveCustomerHandler={saveCustomerHandler}
          validCustomerName={validCustomerName}
          validCustomerEmail={validCustomerEmail}
        />
      </div>
      <br />
      <Card>
        {searchTerm.length === 0 ? (
          <CustomerList customers={customers} />
        ) : (
          <CustomerList customers={searchResult} />
        )}
      </Card>
    </div>
  );
};

CustomersPage.propTypes = {
  auth: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  validCustomerName: PropTypes.bool.isRequired,
  validCustomerEmail: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customers: state.customers,
    validCustomerName: state.inputValidation.validCustomerName,
    validCustomerEmail: state.inputValidation.validCustomerEmail,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersPage);
