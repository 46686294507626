import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const logsReducer = (state = initialState.logs, action) => {
  switch (action.type) {
    case types.LOAD_LOGS_SUCCESS:
      return action.payload.logs;
    default:
      return state;
  }
};

export default logsReducer;
