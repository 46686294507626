import * as types from "./actionsTypes";
import * as deviceApi from "../api/deviceApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export const loadDevices = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  deviceApi
    .getDevices(auth)
    .then((devices) => {
      dispatch({
        type: types.LOAD_DEVICES_SUCCESS,
        payload: {
          devices: devices,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const getSingleDeviceByIMEI = (auth, IMEI) => async (dispatch) => {
  dispatch(beginApiCall());

  dispatch({
    type: "LOADING_DEVICE",
    payload: {
      isLoading: true,
    },
  });

  deviceApi
    .getSingleDevice(auth, IMEI)
    .then((device) => {
      dispatch({
        type: types.LOAD_DEVICE_DETAILS_SUCCESS,
        payload: {
          device: device,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
