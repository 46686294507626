import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Time
import moment from "moment";

// Components
import RequestModal from "./RequestModal";
import Spinner from "../../common/Spinner";

// Redux
import { connect, useDispatch } from "react-redux";
import {
  saveModemRequest,
  loadModemRequests,
  deleteModemRequest,
} from "../../../actions/deviceModemActions";

// Styling
import { Button } from "react-bootstrap";

const DeviceRequestsPane = ({
  auth,
  device,
  modemRequests,
  apiCallsInProgress,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const [RequestModalShow, setRequestModalShow] = useState(false);
  const [Request, setRequest] = useState({
    IMEI: device.IMEI,
    Command: "",
  });

  useEffect(() => {
    RefreshRequest();
  }, [isLoading]);

  const timeout = () => {
    setTimeout(() => {
      RefreshRequest();
    }, 500);
  };

  const ReqOnclickhander = (e) => {
    let reqID = e.currentTarget.getAttribute("id");
    let Request = modemRequests.find((a) => a._id == reqID);
    setRequest(Request);
    setRequestModalShow(true);
  };

  const OnSave = (event) => {
    event.preventDefault();
    dispatch(saveModemRequest(auth, Request));
    setRequestModalShow(false);
    setRequest({ IMEI: device.IMEI, Command: "" });
    timeout();
  };

  const OnRemove = (event) => {
    event.preventDefault();
    dispatch(deleteModemRequest(auth, Request));
    setRequestModalShow(false);
    timeout();
  };

  const OnCancel = () => setRequestModalShow(false);

  const RefreshRequest = () => {
    dispatch(loadModemRequests(auth, device.IMEI));
  };

  return (
    <>
      {isLoading | (apiCallsInProgress > 0) ? (
        <Spinner />
      ) : (
        <>
          <div className="request-actions">
            <Button
              variant="light"
              onClick={RefreshRequest}
              style={{ float: "right" }}
            >
              ⟳
            </Button>

            <Button
              variant="primary"
              onClick={() => {
                setRequest({ IMEI: device.IMEI, Command: "" });
                setRequestModalShow(true);
              }}
            >
              Add Request
            </Button>

            <RequestModal
              isLoading={isLoading}
              show={RequestModalShow}
              request={Request}
              onSave={OnSave}
              onRemove={OnRemove}
              onCancel={OnCancel}
              onHide={() => setRequestModalShow(false)}
              setRequest={setRequest}
            />
          </div>
          <br />
          <table className="table table-striped table-hover">
            <thead className="thead-inverse">
              <tr>
                <th>Command</th>
                <th>Created</th>
                <th>Executed</th>
                <th>CommandType</th>
              </tr>
            </thead>
            <tbody>
              {modemRequests.map((Modemrequest) => {
                return (
                  <tr
                    className="table-row"
                    key={Modemrequest._id}
                    id={Modemrequest._id}
                    onClick={ReqOnclickhander}
                  >
                    <td>{Modemrequest.Command}</td>
                    <td>
                      {moment(Modemrequest.Created).format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </td>
                    <td>
                      {Modemrequest.Executed != null
                        ? moment(Modemrequest.Executed).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )
                        : ""}
                    </td>
                    <td>{Modemrequest.CommandType}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

DeviceRequestsPane.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  modemRequests: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.deviceDetails.device,
    modemRequests: state.deviceDetails.modemRequests,
    apiCallsInProgress: state.apiCallsInProgress,
    isLoading: state.deviceDetails.isLoading,
  };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceRequestsPane);
