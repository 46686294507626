import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const inputValidationReducer = (
  state = initialState.inputValidation,
  action
) => {
  switch (action.type) {
    case types.INVALID_USER_SIGNUP_NAME:
      return {
        ...state,
        validName: false,
      };
    case types.VALID_USER_SIGNUP_NAME:
      return {
        ...state,
        validName: true,
      };
    case types.INVALID_CUSTOMER_SIGNUP_NAME:
      return {
        ...state,
        validCustomerName: false,
      };
    case types.VALID_CUSTOMER_SIGNUP_NAME:
      return {
        ...state,
        validCustomerName: true,
      };

    case types.INVALID_CUSTOMER_SIGNUP_EMAIL:
      return {
        ...state,
        validCustomerEmail: false,
      };
    case types.VALID_CUSTOMER_SIGNUP_EMAIL:
      return {
        ...state,
        validCustomerEmail: true,
      };
    default:
      return state;
  }
};

export default inputValidationReducer;
