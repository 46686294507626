import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";

// Redux
import { useDispatch, connect } from "react-redux";
import { saveConfigList } from "../../../actions/configListAction";

// Styling
import { Form, Row, Col, Button, Alert } from "react-bootstrap";

// Components
import Spinner from "../../common/Spinner";

const DeviceSettingsPane = ({ auth, device, isLoading }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [changedSettings, setChangedSettings] = useState({});

  // CURRENT DEVICE SETTINGS [Device.SettingsConfig]
  const [deviceConfigSettings] = useState({
    iButton: device.SettingsConfig.iButton,
    PrivateSwitchEnable: device.SettingsConfig.PrivateSwitchEnable,
    D8Enable: device.SettingsConfig.D8Enable,
    TachographType: device.SettingsConfig.TachographType,
    FutureBool1: device.SettingsConfig.FutureBool1,
    RTStandbyInterval: device.SettingsConfig.RTStandbyInterval,
    RTDatatimeInterval: device.SettingsConfig.RTDatatimeInterval,
    RTDatadistanceInterval: device.SettingsConfig.RTDatadistanceInterval,
    RCDateTimeInterval: device.SettingsConfig.RCDateTimeInterval,
    RCDataDistanceInterval: device.SettingsConfig.RCDataDistanceInterval,
    RCDataTriggerMask: device.SettingsConfig.RCDataTriggerMask,
    TDDataTimeInterval: device.SettingsConfig.TDDataTimeInterval,
    TDDataDistanceInterval: device.SettingsConfig.TDDataDistanceInterval,
    STDDataTriggerMask: device.SettingsConfig.STDDataTriggerMask,
    iButtonClearInterval: device.SettingsConfig.iButtonClearInterval,
    FutureInt1: device.SettingsConfig.FutureInt1,
    FutureInt2: device.SettingsConfig.FutureInt2,
  });

  // FUTURE DEVICE SETTINGS [Device.Configs[0].SettingsConfig]
  const [configListSettings] = useState({
    iButton: device.Configs[0].SettingsConfig.iButton,
    PrivateSwitchEnable: device.Configs[0].SettingsConfig.PrivateSwitchEnable,
    D8Enable: device.Configs[0].SettingsConfig.D8Enable,
    TachographType: device.Configs[0].SettingsConfig.TachographType,
    FutureBool1: device.Configs[0].SettingsConfig.FutureBool1,
    RTStandbyInterval: device.Configs[0].SettingsConfig.RTStandbyInterval,
    RTDatatimeInterval: device.Configs[0].SettingsConfig.RTDatatimeInterval,
    RTDatadistanceInterval:
      device.Configs[0].SettingsConfig.RTDatadistanceInterval,
    RCDateTimeInterval: device.Configs[0].SettingsConfig.RCDateTimeInterval,
    RCDataDistanceInterval:
      device.Configs[0].SettingsConfig.RCDataDistanceInterval,
    RCDataTriggerMask: device.Configs[0].SettingsConfig.RCDataTriggerMask,
    TDDataTimeInterval: device.Configs[0].SettingsConfig.TDDataTimeInterval,
    TDDataDistanceInterval:
      device.Configs[0].SettingsConfig.TDDataDistanceInterval,
    STDDataTriggerMask: device.Configs[0].SettingsConfig.STDDataTriggerMask,
    iButtonClearInterval: device.Configs[0].SettingsConfig.iButtonClearInterval,
    FutureInt1: device.Configs[0].SettingsConfig.FutureInt1,
    FutureInt2: device.Configs[0].SettingsConfig.FutureInt2,
  });

  function inputHandler(e) {
    if (e.target.type === "checkbox") {
      const value = e.target.checked;
      setChangedSettings({
        ...changedSettings,
        [e.target.name]: value ? "1" : "0",
      });
    } else {
      const value = e.target.value;
      setChangedSettings({
        ...changedSettings,
        [e.target.name]: value,
      });
    }
  }

  const saveSettingsHandler = async (e) => {
    e.preventDefault();
    dispatch(saveConfigList(auth, device.IMEI, changedSettings));
    history.go(0);
  };

  const deviceInSyncHandler = () => {
    for (const key in deviceConfigSettings) {
      if (deviceConfigSettings[key] !== configListSettings[key]) {
        return false;
      }
    }
    return true;
  };

  const [deviceInSync] = useState(deviceInSyncHandler);

  return (
    <>
      {!isLoading ? (
        <Form>
          {deviceInSync ? (
            ""
          ) : (
            <Alert variant="warning">Device is not in sync</Alert>
          )}
          <Form.Row>
            <Form.Label column lg={3}>
              iButton
              <i>
                {deviceConfigSettings.iButton == configListSettings.iButton
                  ? ""
                  : deviceConfigSettings.iButton === "1"
                  ? " (Currently ON)"
                  : " (Currently OFF)"}
              </i>
            </Form.Label>
            <Col>
              <Form.Check
                name="iButton"
                value={configListSettings.iButton}
                defaultChecked={
                  configListSettings.iButton === "1" ? true : false
                }
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              PrivateSwitchEnable
              <i>
                {deviceConfigSettings.PrivateSwitchEnable ==
                configListSettings.PrivateSwitchEnable
                  ? ""
                  : deviceConfigSettings.PrivateSwitchEnable === "1"
                  ? " (Currently ON)"
                  : " (Currently OFF)"}
              </i>
            </Form.Label>
            <Col>
              <Form.Check
                name="PrivateSwitchEnable"
                value={configListSettings.PrivateSwitchEnable}
                defaultChecked={
                  configListSettings.PrivateSwitchEnable === "1" ? true : false
                }
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              D8Enable
              <i>
                {deviceConfigSettings.D8Enable == configListSettings.D8Enable
                  ? ""
                  : deviceConfigSettings.D8Enable === "1"
                  ? " (Currently ON)"
                  : " (Currently OFF)"}
              </i>
            </Form.Label>
            <Col>
              <Form.Check
                name="D8Enable"
                value={configListSettings.D8Enable}
                defaultChecked={
                  configListSettings.D8Enable === "1" ? true : false
                }
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              TachographType
              <i>
                {deviceConfigSettings.TachographType ==
                configListSettings.TachographType
                  ? ""
                  : deviceConfigSettings.TachographType == "0"
                  ? " (Currently None)"
                  : " (Currently " + deviceConfigSettings.TachographType + ")"}
              </i>
            </Form.Label>
            <Col sm="9">
              <Form.Check
                name="TachographType"
                type="radio"
                label="VDO"
                className="radio"
                defaultChecked={
                  configListSettings.TachographType === "vdo" ? true : false
                }
                value="vdo"
                onChange={inputHandler}
              />
              <Form.Check
                name="TachographType"
                type="radio"
                label="Stoneridge"
                className="radio"
                defaultChecked={
                  configListSettings.TachographType === "stoneridge"
                    ? true
                    : false
                }
                value="stoneridge"
                onChange={inputHandler}
              />
              <Form.Check
                name="TachographType"
                type="radio"
                label="None"
                className="radio"
                defaultChecked={
                  configListSettings.TachographType === "0" ? true : false
                }
                value="0"
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Form.Label column lg={3}>
              FutureBool1
              <i>
                {deviceConfigSettings.FutureBool1 ==
                configListSettings.FutureBool1
                  ? ""
                  : " (Currently " + deviceConfigSettings.FutureBool1 + ")"}
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                name="FutureBool1"
                defaultValue={configListSettings.FutureBool1}
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Form.Label column lg={3}>
              RTStandbyInterval
              <i>
                {deviceConfigSettings.RTStandbyInterval ==
                configListSettings.RTStandbyInterval
                  ? ""
                  : " (Currently " +
                    deviceConfigSettings.RTStandbyInterval +
                    ")"}
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                name="RTStandbyInterval"
                defaultValue={configListSettings.RTStandbyInterval}
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              RTDatatimeInterval
              <i>
                {deviceConfigSettings.RTDatatimeInterval ==
                configListSettings.RTDatatimeInterval
                  ? ""
                  : " (Currently " +
                    deviceConfigSettings.RTDatatimeInterval +
                    ")"}
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                name="RTDatatimeInterval"
                defaultValue={configListSettings.RTDatatimeInterval}
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              RTDatadistanceInterval
              <i>
                {deviceConfigSettings.RTDatadistanceInterval ==
                configListSettings.RTDatadistanceInterval
                  ? ""
                  : " (Currently " +
                    deviceConfigSettings.RTDatadistanceInterval +
                    ")"}
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                name="RTDatadistanceInterval"
                defaultValue={configListSettings.RTDatadistanceInterval}
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              RCDateTimeInterval
              <i>
                {deviceConfigSettings.RCDateTimeInterval ==
                configListSettings.RCDateTimeInterval
                  ? ""
                  : " (Currently " +
                    deviceConfigSettings.RCDateTimeInterval +
                    ")"}
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                name="RCDateTimeInterval"
                defaultValue={configListSettings.RCDateTimeInterval}
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              RCDataDistanceInterval
              <i>
                {deviceConfigSettings.RCDataDistanceInterval ==
                configListSettings.RCDataDistanceInterval
                  ? ""
                  : " (Currently " +
                    deviceConfigSettings.RCDataDistanceInterval +
                    ")"}
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                name="RCDataDistanceInterval"
                defaultValue={configListSettings.RCDataDistanceInterval}
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              RCDataTriggerMask
              <i>
                {deviceConfigSettings.RCDataTriggerMask ==
                configListSettings.RCDataTriggerMask
                  ? ""
                  : " (Currently " +
                    deviceConfigSettings.RCDataTriggerMask +
                    ")"}
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                name="RCDataTriggerMask"
                defaultValue={configListSettings.RCDataTriggerMask}
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              TDDataTimeInterval
              <i>
                {deviceConfigSettings.TDDataTimeInterval ==
                configListSettings.TDDataTimeInterval
                  ? ""
                  : " (Currently " +
                    deviceConfigSettings.TDDataTimeInterval +
                    ")"}
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                name="TDDataTimeInterval"
                defaultValue={configListSettings.TDDataTimeInterval}
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              TDDataDistanceInterval
              <i>
                {deviceConfigSettings.TDDataDistanceInterval ==
                configListSettings.TDDataDistanceInterval
                  ? ""
                  : " (Currently " +
                    deviceConfigSettings.TDDataDistanceInterval +
                    ")"}
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                name="TDDataDistanceInterval"
                defaultValue={configListSettings.TDDataDistanceInterval}
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              STDDataTriggerMask
              <i>
                {deviceConfigSettings.STDDataTriggerMask ==
                configListSettings.STDDataTriggerMask
                  ? ""
                  : " (Currently " +
                    deviceConfigSettings.STDDataTriggerMask +
                    ")"}
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                name="STDDataTriggerMask"
                defaultValue={configListSettings.STDDataTriggerMask}
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              iButtonClearInterval
              <i>
                {deviceConfigSettings.iButtonClearInterval ==
                configListSettings.iButtonClearInterval
                  ? ""
                  : " (Currently " +
                    deviceConfigSettings.iButtonClearInterval +
                    ")"}
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                name="iButtonClearInterval"
                defaultValue={configListSettings.iButtonClearInterval}
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              FutureInt1
              <i>
                {deviceConfigSettings.FutureInt1 ==
                configListSettings.FutureInt1
                  ? ""
                  : " (Currently " + deviceConfigSettings.FutureInt1 + ")"}
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                name="FutureInt1"
                defaultValue={configListSettings.FutureInt1}
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label column lg={3}>
              FutureInt2
              <i>
                {deviceConfigSettings.FutureInt2 ==
                configListSettings.FutureInt2
                  ? ""
                  : " (Currently " + deviceConfigSettings.FutureInt2 + ")"}
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                name="FutureInt2"
                defaultValue={configListSettings.FutureInt2}
                onChange={inputHandler}
              />
            </Col>
          </Form.Row>

          <hr></hr>
          <Form.Group>
            <Row>
              <Col sm="11">
                <Button
                  variant="outline-warning"
                  onClick={() => history.push("/devices")}
                >
                  Back
                </Button>
              </Col>
              <Col sm="1">
                <Button
                  variant="success"
                  onClick={(e) => saveSettingsHandler(e)}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      ) : (
        <Spinner />
      )}
    </>
  );
};

DeviceSettingsPane.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.deviceDetails.device,
    isLoading: state.deviceDetails.isLoading,
  };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettingsPane);
