import React from "react";

import { Card } from "react-bootstrap";

const StorePage = () => {
  return (
    <div className="store">
      <h2 className="title">Store</h2>
      <Card>
        <Card.Header>Store</Card.Header>
        <Card.Body>This page is currently under construction.</Card.Body>
        <Card.Footer>See you soon!</Card.Footer>
      </Card>
    </div>
  );
};

export default StorePage;
