import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Time
import moment from "moment";

// Redux
import { useDispatch, connect } from "react-redux";
import { getDeviceCustomerHistory } from "../../../actions/deviceCustomerActions";

const DeviceCustomerHistoryPane = ({
  auth,
  device,
  customerHistory,
  isLoading,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeviceCustomerHistory(auth, device.IMEI));
  }, []);

  const history = customerHistory.sort(function (a, b) {
    return new Date(b.start) - new Date(a.start);
  });

  return (
    <>
      {!isLoading ? (
        <table className="table table-striped table-hover">
          <thead className="thead-inverse">
            <tr>
              <th>Customer Name</th>
              <th>Start</th>
              <th>End</th>
            </tr>
          </thead>
          <tbody>
            {history.map((customer) => {
              return (
                <tr className="table-row" key={customer._id}>
                  <td>{customer.customer[0].name}</td>
                  <td>
                    {moment(customer.start).format("DD-MM-YYYY HH:mm:ss")}
                  </td>
                  <td>
                    {customer.end === null
                      ? ""
                      : moment(customer.end).format("DD-MM-YYYY HH:mm:ss")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        ""
      )}
    </>
  );
};

DeviceCustomerHistoryPane.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customerHistory: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.deviceDetails.device,
    customerHistory: state.deviceDetails.customerHistory,
    isLoading: state.deviceDetails.isLoading,
  };
}
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceCustomerHistoryPane);
