import React from "react";
import { Route, Switch } from "react-router-dom";
import Header from "./pages/common/Header";
import PageNotFound from "./pages/PageNoteFound";
import LoginPage from "./pages/login/LoginPage";
import Logout from "./pages/login/LogoutPage";
import HomePage from "./pages/home/HomePage";
import AboutPage from "./pages/about/AboutPage";
import ManageDeviceDetailsPage from "./pages/deviceDetails/ManageDeviceDetailsPage";
import EndpointPage from "./pages/endpoints/EndpointPage";
import LogPage from "./pages/log/LogPage";
import RawmessagesPage from "./pages/rawmessages/RawmessagesPage";
import AdminPage from "./pages/admin/AdminPage";
import DevicesPage from "./pages/devices/DevicesPage";
import CustomersPage from "./pages/customers/CustomersPage";
import CustomerInfoPage from "./pages/customerDetails/CustomerInfoPage";
import FirmwarePage from "./pages/firmware/FirmwarePage";

import PrivateRoute from "./components/PrivateRoute";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CanConfPage from "./pages/can-conf/CanConfPage";
import StorePage from "./pages/store/StorePage";

function App() {
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/logout" component={Logout} />
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute path="/about" component={AboutPage} />
          <PrivateRoute path="/devices" component={DevicesPage} />
          <PrivateRoute
            path="/device/:id"
            component={ManageDeviceDetailsPage}
          />
          <PrivateRoute path="/endpoints" component={EndpointPage} />
          <PrivateRoute path="/log" component={LogPage} />
          <PrivateRoute path="/firmware" component={FirmwarePage} />
          <PrivateRoute path="/canconf" component={CanConfPage} />
          <PrivateRoute path="/store" component={StorePage} />
          <PrivateRoute path="/rawmsg" component={RawmessagesPage} />
          <PrivateRoute path="/admin" component={AdminPage} />
          <PrivateRoute path="/customers" component={CustomersPage} />
          <PrivateRoute path="/customer/:id" component={CustomerInfoPage} />
          <PrivateRoute component={PageNotFound} />
        </Switch>
        <ToastContainer position="top-right" autoClose={3500} closeOnClick />
      </div>
    </div>
  );
}

export default App;
