import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/dashboard/";

export function getDashboard(auth) {
  return fetch(baseUrl, {
    headers: { "x-access-token": auth.user.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}
