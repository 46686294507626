import * as types from "./actionsTypes";
import * as dashboardApi from "../api/dashboardApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export const loadDashboard = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  dashboardApi
    .getDashboard(auth)
    .then((dashboard) => {
      dispatch({
        type: types.LOAD_DASHBOARD_SUCCESS,
        payload: {
          dashboard: dashboard[0],
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
