import * as types from "./actionsTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { setMessage, clearMessage } from "./messageActions";

import AuthService from "../api/authApi";

export function loginSuccess(data) {
  return { type: types.LOGIN_SUCCESS, payload: { user: data } };
}

export function loginFailed(message) {
  return { type: types.LOGIN_FAIL, message };
}

export function Removesession() {
  return { type: types.LOGOUT };
}

export function login(username, password) {
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const data = await AuthService.login(username, password);
      dispatch(clearMessage());
      dispatch(loginSuccess(data));
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(loginFailed(message));
      dispatch(setMessage(message));
      dispatch(apiCallError());
    }
  };
}

export function logout() {
  return async function (dispatch) {
    AuthService.logout();
    dispatch(Removesession());
  };
}
