import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import ChangeCustomerModal from "./ChangeCustomerModal";

//Compoments
import { SortedTable } from "../common/SortedTable";

const DeviceList = ({
  disableOptions,
  devices,
  selected,
  setSelected,
  singleSelect,
  setSingleSelect,
  show,
  setShow,
  customer,
  setCustomer,
  saveDevices,
}) => {
  const { list, requestSort, sortConfig } = SortedTable(devices);

  const handleChangeCustomerModal = () => setShow(true);

  const tableRowSelected = (e) => {
    if (e.target.checked === true) {
      let selectedList = [...selected, e.target.value];
      setSelected(selectedList);
    } else {
      let unSelectedList = [...selected];
      let index = unSelectedList.indexOf(e.target.value);
      if (index !== -1) {
        unSelectedList.splice(index, 1);
        setSelected(unSelectedList);
      }
    }
  };

  const addSingleSelect = (device) => {
    setSingleSelect(device);
    if (selected.includes(device)) {
      return;
    }
    let selectedList = [...selected, device];
    setSelected(selectedList);
  };

  const removeSingleSelect = () => {
    let unSelectedList = [...selected];
    let index = unSelectedList.indexOf(singleSelect);
    if (index !== -1) {
      unSelectedList.splice(index, 1);
      if (show === true) {
        return;
      }
    }
    setSingleSelect("");
  };

  const setCheckboxes = (e) => {
    if (e.target.checked === true) {
      let deviceList = [];
      devices.forEach((device) => {
        deviceList.push(device.IMEI);
      });
      setSelected(deviceList);
    } else {
      setSelected([]);
    }
  };

  const clearAllSelected = () => {
    setSelected([]);
    setSingleSelect("");
  };

  const openNewTab = () => {
    const device = devices.filter((device) => device.IMEI == singleSelect);
    window.open("/device/" + device[0]._id);
  };

  return (
    <>
      <table className="table table-striped table-hover devices-table">
        <thead className="thead-inverse">
          <tr>
            <th className="checkbox-table-cell">
              <input
                disabled={disableOptions}
                type="checkbox"
                className="checkbox-table-style"
                checked={devices.length === selected.length}
                onChange={(e) => setCheckboxes(e)}
              ></input>
            </th>
            <th className="device-table-customer">
              Customer
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("customer")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "customer" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "customer" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
            <th>
              IMEI
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("IMEI")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "IMEI" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "IMEI" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
            <th>
              SN
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("SerialNumber")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "SerialNumber" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "SerialNumber" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
            <th>
              Hardware
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("HardwareVersion")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "HardwareVersion" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "HardwareVersion" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
            <th>
              Modem
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("GPSMODEMVersion")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "GPSMODEMVersion" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "GPSMODEMVersion" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
            <th>
              FW
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("AddonBoardVersion")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "AddonBoardVersion" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "AddonBoardVersion" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
            <th>
              CAN
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("CANVersion")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "CANVersion" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "CANVersion" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
            <th>
              CAN2
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("CAN2Version")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "CAN2Version" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "CAN2Version" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
            <th>
              Conf
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("ConfigurationVersion")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "ConfigurationVersion" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "ConfigurationVersion" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
            <th>
              ICCID
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("ICCID")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "ICCID" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "ICCID" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
            <th>
              APN
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("APN")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "APN" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "APN" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
            <th className="device-table-inSync">
              InSync
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("Configs")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "Configs" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "Configs" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
            <th>
              LastMsg
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("Rawmessage")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "Rawmessage" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "Rawmessage" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {list.length === 0 ? (
            <tr>
              <td colSpan="14" style={{ textAlign: "center" }}>
                No devices found
              </td>
            </tr>
          ) : (
            list.map((device) => {
              return (
                <ContextMenuTrigger
                  attributes={
                    selected.includes(device.IMEI)
                      ? {
                          className: "row-selected",
                        }
                      : {}
                  }
                  id="table-row-right-click-menu"
                  uri={device.IMEI}
                  collect={(props) => props}
                  renderTag="tr"
                  key={device.IMEI}
                >
                  <td className="options-table-cell">
                    <input
                      disabled={disableOptions}
                      type="checkbox"
                      className="options-table-cell"
                      name="device"
                      value={device.IMEI}
                      onChange={tableRowSelected}
                      checked={selected.includes(device.IMEI)}
                    ></input>
                  </td>

                  <td className="device-table-customer">
                    {device.customer.length === 0
                      ? ""
                      : device.customer[0].name}
                  </td>
                  <td>
                    <Link to={"/device/" + device._id}>{device.IMEI}</Link>
                  </td>
                  <td>{device.SerialNumber}</td>
                  <td>{device.HardwareVersion}</td>
                  <td>{device.GPSMODEMVersion}</td>
                  <td>{device.AddonBoardVersion}</td>
                  <td>{device.CANVersion}</td>
                  <td>{device.CAN2Version}</td>
                  <td>{device.ConfigurationVersion}</td>
                  <td>{device.ICCID}</td>
                  <td>{device.APN}</td>
                  <td>
                    {device.Configs[0].DeviceinSync == true ? "Yes" : "No"}
                  </td>
                  <td>
                    {device.Rawmessage[0].Timestamp ===
                    "0000-00-00T00:00:00.000+00:00"
                      ? ""
                      : moment(device.Rawmessage[0].Timestamp).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                  </td>
                </ContextMenuTrigger>
              );
            })
          )}
        </tbody>
      </table>
      {!disableOptions && (
        <>
          <ContextMenu
            id="table-row-right-click-menu"
            collect={(props) => props}
            onShow={(e) => addSingleSelect(e.detail.data.uri)}
            onHide={removeSingleSelect}
          >
            <MenuItem onClick={handleChangeCustomerModal}>
              Change customer
            </MenuItem>
            <MenuItem onClick={openNewTab}>Open in new tab</MenuItem>
            <MenuItem onClick={clearAllSelected}>Cancel</MenuItem>
          </ContextMenu>
          {(singleSelect != null || selected.length > 0) && (
            <ChangeCustomerModal
              show={show}
              setShow={setShow}
              selected={selected}
              customer={customer}
              setCustomer={setCustomer}
              saveDevices={saveDevices}
            />
          )}
        </>
      )}
    </>
  );
};

DeviceList.propTypes = {
  devices: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  singleSelect: PropTypes.string.isRequired,
  setSingleSelect: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  customer: PropTypes.string.isRequired,
  setCustomer: PropTypes.func.isRequired,
  saveDevices: PropTypes.func.isRequired,
  disableOptions: PropTypes.bool.isRequired,
};

export default DeviceList;
