import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { logout } from "../../actions/authActions";
import PropTypes from "prop-types";
import Spinner from "../common/Spinner";

async function logoutinit(logout) {
  await logout();
  return;
}
function Logout({ logout, auth }) {
  useEffect(() => {
    logoutinit(logout);
  }, []);

  return <>{auth.isLoggedIn == true ? <Spinner /> : <Redirect to="/" />}</>;
}

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
