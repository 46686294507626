import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/frontendusers/";

export function loadCustomerFrontendUsers(auth, id) {
  return fetch(baseUrl + "customer/" + id, {
    headers: { "x-access-token": auth.user.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getfrontendusers(auth) {
  return fetch(baseUrl, {
    headers: { "x-access-token": auth.user.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getroles(auth) {
  return fetch(baseUrl + "roles/", {
    headers: { "x-access-token": auth.user.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveFrontEndUser(auth, user) {
  return fetch(process.env.API_URL + "/api/auth/", {
    method: user.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
    body: JSON.stringify(user),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function checkValidUser(auth, username) {
  var test = { username: username };
  return fetch(process.env.API_URL + "/api/auth/username", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
    body: JSON.stringify(test),
  }).then(handleResponse);
}

export function removeFrontEndUser(auth, id) {
  var test = { _id: id };
  return fetch(baseUrl, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
    body: JSON.stringify(test),
  })
    .then(handleResponse)
    .catch(handleError);
}
