import * as types from "./actionsTypes";
import EndpointAPI from "../api/endpointApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadEndpointsSuccess(endpoints) {
  return { type: types.LOAD_ENDPOINTS_SUCCESS, endpoints };
}

export function loadEndpoints(auth) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return EndpointAPI.Get(auth)
      .then((endpoints) => {
        dispatch(loadEndpointsSuccess(endpoints));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
