import * as types from "./actionsTypes";
import * as deviceRawmesageApi from "../api/deviceRawmesageApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export const loadRawMessages = (auth, IMEI) => async (dispatch) => {
  dispatch(beginApiCall());

  dispatch({
    type: "LOADING_DEVICE",
    payload: {
      isLoading: true,
    },
  });

  deviceRawmesageApi
    .getrawmessagesforDevices(auth, IMEI)
    .then((messages) => {
      dispatch({
        type: types.LOAD_DEVICE_RAW_MESSAGES_SUCCESS,
        payload: {
          rawMessages: messages,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
