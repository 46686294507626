import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { useDispatch } from "react-redux";

import {
  checkValidCustomerName,
  checkValidCustomerEmail,
} from "../../../actions/inputValidationActions";
import Countries from "../../../util/Countries";

import { Modal, Form, Button } from "react-bootstrap";

const UpdateCustomerModal = ({
  auth,
  show,
  setShow,
  customer,
  setCustomer,
  validCustomerName,
  validCustomerEmail,
  current,
  updateCustomerHandler,
}) => {
  const dispatch = useDispatch();
  const [validForm, setValidForm] = useState(false);
  const validateCustomerName = () => {
    if (current.name != customer.name) {
      dispatch(checkValidCustomerName(auth, customer.name));
    }
  };

  const validateCustomerEmail = () => {
    if (current.email != customer.email) {
      dispatch(checkValidCustomerEmail(auth, customer.email));
    }
  };

  useEffect(() => {
    let validEmail;
    let validName;

    if (customer.name.length >= 3) {
      validName = true;
    } else {
      validName = false;
    }

    if (
      customer.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      validEmail = true;
    } else {
      validEmail = false;
    }

    if (
      validEmail === true &&
      validName === true &&
      validCustomerName === true &&
      validCustomerEmail === true
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [validCustomerName, validCustomerEmail, customer]);

  const inputHandler = (e) => {
    let value = "";
    if (e.target.name === "name") {
      value = e.target.value.replace(/[^\w\s]/gi, "");
    } else {
      value = e.target.value;
    }
    setCustomer({
      ...customer,
      [e.target.name]: value,
    });
  };

  const checkboxHandler = (e) => {
    const value = e.target.checked;
    setCustomer({
      ...customer,
      [e.target.name]: value,
    });
  };

  // const setFeatures = (input) => {
  //   setCustomer({
  //     ...customer,
  //     features: input,
  //   });
  // };
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const saveIt = async () => {
    await delay(1000);
    updateCustomerHandler();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <div onClick={handleShow}>Update details</div>
      <Modal show={show} onHide={handleClose} centered className="text-center">
        <Modal.Header closeButton>
          <Modal.Title>Update Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder="Customer Name"
                type="text"
                name="name"
                value={customer.name}
                onChange={inputHandler}
                onBlur={validateCustomerName}
                className={validCustomerName ? "" : "input-field-invalid"}
              />
              {!validCustomerName && (
                <p className="text-invalid">
                  <small> This name is already taken.</small>
                </p>
              )}
            </Form.Row>
            <Form.Row>
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                placeholder="Customer Email adress"
                type="text"
                name="email"
                value={customer.email}
                onChange={inputHandler}
                onBlur={validateCustomerEmail}
                className={validCustomerEmail ? "" : "input-field-invalid"}
              />
              {!validCustomerEmail && (
                <p className="text-invalid">
                  <small> This e-mail adress is already taken.</small>
                </p>
              )}
            </Form.Row>
            <Form.Row>
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                name="country"
                value={customer.country}
                onChange={inputHandler}
              >
                {Countries.map((country) => {
                  return (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Row>
            <Form.Row>
              <Form.Label>Reseller</Form.Label>
              <Form.Check
                defaultChecked={customer.isReseller ? true : false}
                type="checkbox"
                name="isReseller"
                value={customer.isReseller}
                onChange={checkboxHandler}
                disabled={!auth.user.roles.includes("ROLE_ADMIN")}
              />
            </Form.Row>
            {/* <br />
            <Form.Row>
              <Form.Label>Features</Form.Label>
              <Form.Control
                as="select"
                multiple
                name="features"
                value={customer.features}
                disabled
                onChange={(e) =>
                  setFeatures(
                    [].slice
                      .call(e.target.selectedOptions)
                      .map((item) => item.value)
                  )
                }
              >
                <option value="Tacho download">Tacho download</option>
                <option value="EasyDrive">EasyDrive</option>
                <option value="Fuel consumption">Fuel consumption</option>
              </Form.Control>
            </Form.Row> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" disabled={!validForm} onClick={saveIt}>
            Save Customer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

UpdateCustomerModal.propTypes = {
  auth: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  setCustomer: PropTypes.func.isRequired,
  validCustomerName: PropTypes.bool.isRequired,
  validCustomerEmail: PropTypes.bool.isRequired,
  current: PropTypes.object.isRequired,
  updateCustomerHandler: PropTypes.func.isRequired,
};

export default UpdateCustomerModal;
