import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Styling
import { Form, Row, Col, Button } from "react-bootstrap";

// Components
import Spinner from "../../common/Spinner";

const DeviceCanConfigPane = ({ device, isLoading }) => {
  return (
    <>
      {!isLoading ? (
        <Form>
          <Form.Group as={Row} controlId="formCanSettings">
            <Form.Label column sm="3">
              <h6>
                Status:{" "}
                <i>
                  {device.CANControlerConfig.Status ==
                  device.Configs[0].CANControlerConfig.Status
                    ? ""
                    : " (" + device.CANControlerConfig.Status + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].CANControlerConfig.Status}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                CAN1baudrate:{" "}
                <i>
                  {device.CANControlerConfig.CAN1baudrate ==
                  device.Configs[0].CANControlerConfig.CAN1baudrate
                    ? ""
                    : " (" + device.CANControlerConfig.CAN1baudrate + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].CANControlerConfig.CAN1baudrate}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                CAN1mode:{" "}
                <i>
                  {device.CANControlerConfig.CAN1mode ==
                  device.Configs[0].CANControlerConfig.CAN1mode
                    ? ""
                    : " (" + device.CANControlerConfig.CAN1mode + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].CANControlerConfig.CAN1mode}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                CAN2baudrate:{" "}
                <i>
                  {device.CANControlerConfig.CAN2baudrate ==
                  device.Configs[0].CANControlerConfig.CAN2baudrate
                    ? ""
                    : " (" + device.CANControlerConfig.CAN2baudrate + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].CANControlerConfig.CAN2baudrate}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                CAN2mode:{" "}
                <i>
                  {device.CANControlerConfig.CAN2mode ==
                  device.Configs[0].CANControlerConfig.CAN2mode
                    ? ""
                    : " (" + device.CANControlerConfig.CAN2mode + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].CANControlerConfig.CAN2mode}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                Speedcalibrationvalue:{" "}
                <i>
                  {device.CANControlerConfig.Speedcalibrationvalue ==
                  device.Configs[0].CANControlerConfig.Speedcalibrationvalue
                    ? ""
                    : " (" +
                      device.CANControlerConfig.Speedcalibrationvalue +
                      ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={
                  device.Configs[0].CANControlerConfig.Speedcalibrationvalue
                }
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                Fuelcalibrationvalue:{" "}
                <i>
                  {device.CANControlerConfig.Fuelcalibrationvalue ==
                  device.Configs[0].CANControlerConfig.Fuelcalibrationvalue
                    ? ""
                    : " (" +
                      device.CANControlerConfig.Fuelcalibrationvalue +
                      ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={
                  device.Configs[0].CANControlerConfig.Fuelcalibrationvalue
                }
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                PTOstatus:{" "}
                <i>
                  {device.CANControlerConfig.PTOstatus ==
                  device.Configs[0].CANControlerConfig.PTOstatus
                    ? ""
                    : " (" + device.CANControlerConfig.PTOstatus + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].CANControlerConfig.PTOstatus}
              />
            </Col>
          </Form.Group>
          <hr></hr>
          <Form.Group>
            <Row>
              <Col sm="11">
                <Button variant="outline-warning" type="submit">
                  Back
                </Button>
              </Col>
              <Col sm="1">
                <Button variant="success" type="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      ) : (
        <Spinner />
      )}
    </>
  );
};

DeviceCanConfigPane.propTypes = {
  device: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    device: state.deviceDetails.device,
    isLoading: state.deviceDetails.isLoading,
  };
}
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceCanConfigPane);
