import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Router
import { Link } from "react-router-dom";

// Components
import Confirmation from "../../../components/Confirmation";
import { SortedTable } from "../../common/SortedTable";

import {
  updateCustomer,
  removeCustomer,
} from "../../../actions/customerActions";
// Redux
import { connect, useDispatch } from "react-redux";

import UpdateCustomerModal from "./UpdateCustomerModal";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

const CustomerList = ({
  auth,
  customers,
  validCustomerName,
  validCustomerEmail,
}) => {
  const dispatch = useDispatch();
  const { list, requestSort, sortConfig } = SortedTable(customers);
  const [show, setShow] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const selectCustomer = (e) => {
    setSelectedCustomer(
      customers.find((customer) => {
        return customer._id == e;
      })
    );
  };

  useEffect(() => {
    if (selectedCustomer != null) {
      setUpdatedCustomer({
        id: selectedCustomer._id,
        name: selectedCustomer.name,
        email: selectedCustomer.email,
        country: selectedCustomer.country,
        isReseller: selectedCustomer.isReseller,
        reseller_id: selectedCustomer.reseller_id,
        features: selectedCustomer.features,
      });
    }
  }, [selectedCustomer]);

  const [updatedCustomer, setUpdatedCustomer] = useState({
    id: "",
    name: "",
    email: "",
    country: "",
    isReseller: "",
    reseller_id: "",
    features: "",
  });

  const updateCustomerHandler = () => {
    if (!validCustomerName || !validCustomerEmail) {
      alert("Please use a different Name/E-mail adress");
    } else {
      dispatch(updateCustomer(auth, updatedCustomer));
      setShow(false);
      setUpdatedCustomer({
        id: selectedCustomer._id,
        name: selectedCustomer.name,
        email: selectedCustomer.email,
        country: selectedCustomer.country,
        isReseller: selectedCustomer.isResller,
        reseller_id: selectedCustomer.reseller_id,
        features: selectedCustomer.features,
      });
    }
  };

  const removeCustomerHandler = (customer) => {
    if (auth.user.customerId == customer._id) {
      alert("You can't delete yourself!");
      return;
    }
    dispatch(removeCustomer(auth, customer._id));
  };

  return (
    <div className="customer">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>
              Name
              <button
                className="sort-button"
                type="button"
                onClick={() => requestSort("name")}
              >
                {sortConfig !== null
                  ? sortConfig.key === "name" &&
                    sortConfig.direction === "ascending"
                    ? "▼"
                    : sortConfig.key === "name" &&
                      sortConfig.direction === "descending"
                    ? "▲"
                    : "▲"
                  : "▲"}
              </button>
            </th>
            <th>Email</th>
            <th>Country</th>
            <th>isReseller</th>
            <th>Main Reseller</th>
          </tr>
        </thead>
        <tbody>
          {list.length === 0 ? (
            <tr>
              <td colSpan="15" style={{ textAlign: "center" }}>
                No customers found
              </td>
            </tr>
          ) : (
            list.map((customer) => {
              return (
                <ContextMenuTrigger
                  attributes={{
                    className: "table-row",
                  }}
                  id="table-row-right-click-menu"
                  uri={customer._id}
                  collect={(props) => props}
                  renderTag="tr"
                  key={customer._id}
                >
                  <td>
                    <Link to={"/customer/" + customer._id}>
                      {customer.name}
                    </Link>
                  </td>
                  <td>{customer.email}</td>
                  <td>{customer.country}</td>
                  <td>{customer.isReseller ? "Yes" : "No"}</td>
                  <td>
                    {customer.reseller_id != null
                      ? customer.reseller_id.name
                      : ""}
                  </td>
                </ContextMenuTrigger>
              );
            })
          )}
        </tbody>
      </table>
      <ContextMenu
        id="table-row-right-click-menu"
        collect={(props) => props}
        onShow={(e) => selectCustomer(e.detail.data.uri)}
      >
        <MenuItem>
          {selectedCustomer != null && (
            <UpdateCustomerModal
              auth={auth}
              show={show}
              setShow={setShow}
              current={selectedCustomer}
              customer={updatedCustomer}
              setCustomer={setUpdatedCustomer}
              updateCustomerHandler={updateCustomerHandler}
              validCustomerName={validCustomerName}
              validCustomerEmail={validCustomerEmail}
            />
          )}
        </MenuItem>
        <MenuItem>
          <Confirmation
            buttonText="Remove customer"
            description={`Are you sure you want to remove this customer?`}
            onConfirm={() => removeCustomerHandler(selectedCustomer)}
          />
        </MenuItem>
        <MenuItem>Cancel</MenuItem>
      </ContextMenu>
    </div>
  );
};

CustomerList.propTypes = {
  auth: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  validCustomerName: PropTypes.bool.isRequired,
  validCustomerEmail: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    validCustomerName: state.inputValidation.validCustomerName,
    validCustomerEmail: state.inputValidation.validCustomerEmail,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
