import React from "react";

const AboutPage = () => (
    <div>
        <h3>See you soon!</h3>
        <p>
        </p>
    </div>
);

export default AboutPage;
