const baseUrl = process.env.API_URL + "/api/endpoints";
import { handleResponse, handleError } from "./apiUtils";

const Get = function (auth) {
    return fetch(baseUrl, { headers: { 'x-access-token': auth.user.accessToken } })
        .then(handleResponse)
        .catch(handleError);
}

export default {
    Get,

};

