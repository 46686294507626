import * as types from "./actionsTypes";
import * as LogApi from "../api/LogApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export const loadLogs = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  LogApi.getlogs(auth)
    .then((logs) => {
      dispatch({
        type: types.LOAD_LOGS_SUCCESS,
        payload: {
          logs: logs,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
