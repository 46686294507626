import React from "react";
import PropTypes from "prop-types";

// Time
import moment from "moment";

const RawmessageList = ({ rawMessages }) => (
  <>
    <table className="table table-striped table-hover">
      <thead className="thead-inverse">
        <tr>
          <th className="timeStamp-table-cell">TimeStamp</th>
          <th>Valid JSON</th>
          <th>RawMessage</th>
        </tr>
      </thead>
      <tbody className="raw-messages-table-body">
        {rawMessages.length === 0 ? (
          <tr>
            <td colSpan="12" style={{ textAlign: "center" }}>
              No messages found
            </td>
          </tr>
        ) : (
          rawMessages.map((Rawmessage) => {
            return (
              <tr className="table-row" key={Rawmessage._id}>
                <td className="timeStamp-table-cell">
                  {moment(Rawmessage.Timestamp).format("DD-MM-YYYY HH:mm:ss")}
                </td>
                <td className="timeStamp-table-cell">
                  {Rawmessage.ValidJson === true ? "Yes" : "No"}
                </td>
                <td>{Rawmessage.RawMessage}</td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  </>
);

RawmessageList.propTypes = {
  rawMessages: PropTypes.array.isRequired,
};

export default RawmessageList;
