import * as types from '../actions/actionsTypes';
import initialState from './initialState';

export default function endpointReducer(state = initialState.endpoints, action) {
    switch (action.type) {
        case types.LOAD_ENDPOINTS_SUCCESS:
            return action.endpoints;
        case types.LOGOUT:
            return initialState.endpoints;
        default:
            return state;
    }
}