import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// Redux
import { connect, useDispatch } from "react-redux";
import { checkValidUser } from "../../actions/inputValidationActions";

// Styling
import { Form, Button, Modal } from "react-bootstrap";

const UpdateUserModal = ({
  auth,
  validName,
  show,
  current,
  setShow,
  updatedUser,
  setUpdatedUser,
  roles,
  updateUserHandler,
}) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validForm, setValidForm] = useState(false);
  const dispatch = useDispatch();

  const validateUsername = () => {
    if (current.email != updatedUser.email) {
      dispatch(checkValidUser(auth, updatedUser.username));
    }
  };

  const inputHandler = (e) => {
    let value = "";
    if (e.target.name === "username") {
      value = e.target.value.replace(/[^\w\s]/gi, "");
    } else {
      value = e.target.value;
    }
    setUpdatedUser({
      ...updatedUser,
      [e.target.name]: value,
    });
  };

  const roleHandler = (e) => {
    if (e.target.checked === true) {
      // Pak huidige array
      let addedRoles = [...updatedUser.roles, e.target.name];
      // Add deze nieuwe
      setUpdatedUser({
        ...updatedUser,
        roles: addedRoles,
      });
    } else {
      // Pak huidige array
      let removeRoles = [...updatedUser.roles];
      let index = removeRoles.indexOf(e.target.name);
      if (index !== -1) {
        // Verwijder deze
        removeRoles.splice(index, 1);
        setUpdatedUser({
          ...updatedUser,
          roles: removeRoles,
        });
      }
    }
  };

  useEffect(() => {
    let validUsername;
    let validEmail;
    let validPassword;
    let validRoles;

    if (updatedUser.username.length >= 3) {
      validUsername = true;
    } else {
      validUsername = false;
    }

    if (
      updatedUser.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      validEmail = true;
    } else {
      validEmail = false;
    }

    if (updatedUser.password.length > 6) {
      validPassword = true;
    } else {
      validPassword = false;
    }

    if (updatedUser.password === updatedUser.passwordConf) {
      validPassword = true;
    } else {
      validPassword = false;
    }

    if (updatedUser.roles.length > 0) {
      validRoles = true;
    } else {
      validRoles = false;
    }

    if (
      validUsername === true &&
      validEmail === true &&
      validPassword === true &&
      validRoles === true &&
      validName == true
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [updatedUser, validName]);

  return (
    <div className="update-user-modal">
      <div onClick={handleShow}>Update user</div>
      <Modal show={show} onHide={handleClose} centered className="text-center">
        <Modal.Header>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Form>
            <Form.Row>
              <Form.Label>Username</Form.Label>
              <Form.Control
                placeholder="Enter Username"
                type="text"
                name="username"
                onBlur={validateUsername}
                value={updatedUser.username}
                onChange={inputHandler}
                className={validName ? "" : "input-field-invalid"}
              />
              {!validName && (
                <p className="text-invalid">
                  <small> This username is already taken.</small>
                </p>
              )}
            </Form.Row>
            <Form.Row>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={updatedUser.email}
                placeholder="Enter email"
                onChange={inputHandler}
              />
            </Form.Row>
            <Form.Row>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={updatedUser.password}
                placeholder="Leave blank to keep the same"
                onChange={inputHandler}
              />
            </Form.Row>
            <Form.Row>
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                name="passwordConf"
                value={updatedUser.passwordConf}
                placeholder="Leave blank to keep the same"
                onChange={inputHandler}
              />
              {updatedUser.password !== updatedUser.passwordConf && (
                <p className="text-invalid">
                  <small> Passwords dont match.</small>
                </p>
              )}
            </Form.Row>
            {roles.map((role) => {
              return (
                <Form.Row key={role._id}>
                  <Form.Label className="checkbox-label-width">
                    {role.name}
                  </Form.Label>
                  <Form.Check
                    defaultChecked={
                      updatedUser.roles.includes(role.name) ? true : false
                    }
                    type="checkbox"
                    name={role.name}
                    onChange={roleHandler}
                  />
                </Form.Row>
              );
            })}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button
            variant="primary"
            disabled={!validForm}
            onClick={updateUserHandler}
          >
            Save User
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

UpdateUserModal.propTypes = {
  auth: PropTypes.object.isRequired,
  validName: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  current: PropTypes.object.isRequired,
  setShow: PropTypes.func.isRequired,
  updatedUser: PropTypes.object.isRequired,
  setUpdatedUser: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  updateUserHandler: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    users: state.users.users,
    roles: state.users.roles,
    validName: state.inputValidation.validName,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserModal);
