import * as types from "./actionsTypes";
import * as configTemplateApi from "../api/configTemplateApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const loadConfigTemplates = (auth, customer) => async (dispatch) => {
  dispatch(beginApiCall());

  configTemplateApi
    .loadConfigTemplates(auth, customer)
    .then((configTemplates) => {
      dispatch({
        type: types.LOAD_CONFIG_TEMPLATES_SUCCESS,
        payload: {
          configTemplates: configTemplates,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const saveConfigTemplate = (auth, config) => async (dispatch) => {
  configTemplateApi
    .saveConfigTemplate(auth, config)
    .then((configTemplate) => {
      dispatch({
        type: types.SAVE_CONFIG_TEMPLATE_SUCCESS,
        payload: {
          configTemplate: configTemplate,
        },
      });
      toast.success("Config template saved!");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const removeConfigTemplate = (auth, id) => async (dispatch) => {
  configTemplateApi
    .removeConfigTemplate(auth, id)
    .then((res) => {
      dispatch({
        type: types.REMOVE_CONFIG_TEMPLATE_SUCCESS,
        payload: {
          configTemplate: id,
        },
      });
      toast.error(res);
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
