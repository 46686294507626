import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Time
import moment from "moment";

// Redux
import { useDispatch, connect } from "react-redux";
import { loadRawMessages } from "../../../actions/deviceRawMessagesActions";

// Styling
import { Button } from "react-bootstrap";

// Components
import Spinner from "../../common/Spinner";

const DeviceRawMessagesPane = ({ auth, device, rawMessages, isLoading }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    RefresRawmsg();
  }, []);

  const RefresRawmsg = () => {
    dispatch(loadRawMessages(auth, device.IMEI));
  };

  return (
    <>
      {!isLoading ? (
        <>
          <table className="table table-striped table-hover">
            <thead className="thead-inverse">
              <tr>
                <th>Timestamp</th>
                <th>ValidJson</th>
                <th>RawMessage</th>
                <th>
                  <Button
                    style={{ float: "right" }}
                    variant="light"
                    onClick={RefresRawmsg}
                  >
                    ⟳
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody className="raw-messages-table-body">
              {rawMessages.map((rawmessage) => {
                return (
                  <tr className="table-row" key={rawmessage._id}>
                    <td>
                      {moment(rawmessage.Timestamp).format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </td>
                    <td>{rawmessage.ValidJson === true ? "Yes" : ""}</td>
                    <td colSpan="2">{rawmessage.RawMessage}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

DeviceRawMessagesPane.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  rawMessages: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.deviceDetails.device,
    rawMessages: state.deviceDetails.rawMessages,
    isLoading: state.deviceDetails.isLoading,
  };
}
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceRawMessagesPane);
