import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Time
import moment from "moment";

// Redux
import { useDispatch, connect } from "react-redux";
import { loadDeviceState } from "../../../actions/deviceStateAction";

// Styling
import { Form, Col, Button } from "react-bootstrap";

// Components
import Spinner from "../../common/Spinner";

const DeviceTestPane = ({ auth, device, state, isLoading }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadDeviceState(auth, device.IMEI));
  }, [isLoading]);

  const [devicestate, setdevicestate] = useState(state); // eslint-disable-line
  const [LoadDevicestate, setLoadDevicestate] = useState(false);

  const [interval, setintervalid] = useState(); // eslint-disable-line

  const [TestActive, setTestActive] = useState(false);

  const [validConnection, setvalidConnection] = useState(false);
  const [validTimestamp, setvalidTimestamp] = useState(false);
  const [validPosition, setvalidPosition] = useState(false);
  const [validPositionSats, setvalidPositionSats] = useState("0");
  const [validIGNState, setvalidIGNState] = useState(false);
  const [validOdoState, setvalidOdoState] = useState(false);
  const [validVINState, setvalidVINState] = useState(false);
  const [validIbuttonState, setvalidIbuttonState] = useState(false);
  const [validIO1State, setvalidIO1State] = useState(false);
  const [validTestRun, setvalidTestRun] = useState(0);
  const [Testruns, setTestruns] = useState(0);

  const [PreviusIGNState, setPreviusIGNState] = useState({});

  if (devicestate.length != 0 && LoadDevicestate === false) {
    setLoadDevicestate(true);
  }

  useEffect(() => {
    ValidateData();
  }, [state]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, [interval]);

  function ActivationTest() {
    if (TestActive === false) {
      setTestActive(true);
      setvalidConnection(false);
      setvalidTimestamp(false);
      setvalidPosition(false);
      setvalidPositionSats("0");
      setvalidIGNState(false);
      setPreviusIGNState({});
      setvalidOdoState(false);
      setvalidVINState(false);
      setvalidIbuttonState(false);
      setvalidIO1State(false);
      setvalidTestRun(0);
      setTestruns(0);
      console.log("Activate Test");
      var test = setInterval(() => {
        console.log("Loading state");
        dispatch(loadDeviceState(auth, device.IMEI));
      }, 6000);
      setintervalid(test);
    } else {
      setTestActive(false);
      setvalidTestRun(0);
      clearInterval(interval);
    }
  }

  function ValidateData() {
    if (devicestate.length != 0) {
      let moment = require("moment");
      let datenow = moment(Date.now());
      // Validate Connection

      if (validConnection == false) {
        let datemsg = moment(devicestate[0].Timestamp);
        let msgAge = datenow.diff(datemsg, "seconds");
        if (msgAge < 300) {
          setvalidConnection(true);
          console.log("Connection valid");
        }
      }
      // Validate Unit Time
      if (validTimestamp == false) {
        let dateunit = moment(devicestate[0].UnitTime);
        let unitTimedif = datenow.diff(dateunit, "seconds");
        if (unitTimedif < 300 && unitTimedif > -300) {
          setvalidTimestamp(true);
          console.log("UnitTime valid");
        }
      }

      //Validate Position
      if (validTimestamp == true && validPosition == false) {
        if (
          devicestate[0].LAT != "0" &&
          devicestate[0].LON != "0" &&
          devicestate[0].SATCount > 3
        ) {
          setvalidPosition(true);
          setvalidPositionSats(devicestate[0].SATCount);
          console.log("ValidPosition");
        }
      }

      //Validate Ing
      if (validIGNState == false) {
        if (validTimestamp == true) {
          if (
            PreviusIGNState.State == true &&
            devicestate[0].IgnState == false
          ) {
            setvalidIGNState(true);
            console.log("ValidIGN");
          } else {
            if (PreviusIGNState.Timestamp != devicestate[0].Timestamp) {
              setPreviusIGNState({
                State: devicestate[0].IgnState,
                Timestamp: devicestate[0].Timestamp,
              });
              console.log("Set Ign ref. state");
            }
          }
        }
      }

      //Validate ODO
      if (
        validTimestamp == true &&
        validIGNState == true &&
        validOdoState == false
      ) {
        if (devicestate[0].odo > 0) {
          setvalidOdoState(true);
          console.log("ValidOdo");
        }
      }
      //Validate VIN
      if (
        validTimestamp == true &&
        validIGNState == true &&
        validVINState == false
      ) {
        if (devicestate[0].VIN != "0") {
          setvalidVINState(true);
          console.log("ValidVIN");
        }
      }

      //Validate IButton
      if (validTimestamp == true && validIbuttonState == false) {
        if (device.SettingsConfig.iButton == 1) {
          if (devicestate[0].LastKey != "0") {
            setvalidIbuttonState(true);
          }
        } else {
          setvalidIbuttonState(true);
          console.log("Ibutton not configured");
        }
      }

      //Validate IO1
      if (validTimestamp == true && validIO1State == false) {
        if (device.SettingsConfig.PrivateSwitchEnable == 1) {
          if (devicestate[0].IO1State == true) {
            setvalidIO1State(true);
          }
        } else {
          setvalidIO1State(true);
          console.log("PZ not configured");
        }
      }

      //Validate IO2
      // Count Test
      setTestruns(Testruns + 1);

      if (Testruns > 30) {
        setTestActive(false);
        setvalidTestRun(false);
        clearInterval(interval);
        setvalidTestRun(2);
        console.log("Test Failed");
      }

      //Stop test
      if (
        validConnection &&
        validTimestamp &&
        validPosition &&
        validIGNState &&
        validOdoState &&
        validVINState &&
        validIbuttonState &&
        validIO1State
      ) {
        setTestActive(false);
        setvalidTestRun(1);
        clearInterval(interval);
        console.log("Test Done");
      }
    }
  }
  return (
    <>
      {!isLoading ? (
        <>
          <Form>
            <Form.Row>
              <Form.Group as={Col} controlId="IMEI">
                <Form.Label>
                  <h6>IMEI: </h6>
                </Form.Label>
                <Form.Control plaintext readOnly defaultValue={device.IMEI} />
              </Form.Group>
              <Form.Group as={Col} controlId="SN">
                <Form.Label>
                  <h6>Serial Number: </h6>
                </Form.Label>
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={device.SerialNumber}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="HardwareVersion">
                <Form.Label>
                  <h6>HardwareVersion: </h6>
                </Form.Label>
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={device.HardwareVersion}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="VIN">
                <Form.Label>
                  <h6>VIN: </h6>
                </Form.Label>
                <Form.Control plaintext readOnly defaultValue={device.VIN} />
              </Form.Group>
            </Form.Row>
          </Form>
          <Col sm={2}>
            <Button variant="light" onClick={ActivationTest}>
              {TestActive == true ? "Stop Test" : "Start Test"}
            </Button>
          </Col>
          <div>
            {TestActive == true
              ? "Test in progress...  (Turn ON Ignintion) "
              : ""}
            <br></br>
            {validConnection == true
              ? "Connection with Device. Last message : " +
                moment(devicestate[0].Timestamp).format("DD-MM-YYYY HH:mm:ss")
              : ""}
            <br></br>
            {validTimestamp == true
              ? "Valid Unit Time : " +
                moment(devicestate[0].UnitTime).format("DD-MM-YYYY HH:mm:ss")
              : ""}
            <br></br>
            {validTimestamp == true
              ? validIGNState == true
                ? "Valid Ignition"
                : "Turn Off Ignition"
              : ""}
            <br></br>
            {validPosition == true
              ? "Valid GPS Position SatCount : " +
                validPositionSats +
                " Lat : " +
                devicestate[0].LAT +
                " Long : " +
                devicestate[0].LON
              : ""}
            <br></br>
            {validOdoState == true ? "Valid ODO : " + devicestate[0].odo : ""}
            <br></br>
            {validVINState == true ? "Valid VIN : " + devicestate[0].VIN : ""}
            <br></br>
            {device.SettingsConfig.iButton == 1
              ? validIbuttonState == true
                ? "Valid IDButton Key : " + devicestate[0].LastKey
                : ""
              : validIbuttonState == true
              ? "IButton not Configured"
              : ""}
            <br></br>
            {device.SettingsConfig.PrivateSwitchEnable == 1
              ? validIO1State == true
                ? "Valid IO1 (Private Switch) State : " +
                  devicestate[0].IO1State
                : ""
              : validIO1State == true
              ? "ValidIO1(PZ) not Configured"
              : ""}
            <br></br>
            {validTestRun == 1
              ? "Test succeded  " +
                moment(Date.now()).format("DD-MM-YYYY HH:mm:ss")
              : validTestRun == 2
              ? "Test Failed  " +
                moment(Date.now()).format("DD-MM-YYYY HH:mm:ss")
              : ""}
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

DeviceTestPane.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  state: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.deviceDetails.device,
    state: state.deviceDetails.state,
    isLoading: state.deviceDetails.isLoading,
  };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTestPane);
