import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { useDispatch, connect } from "react-redux";
import { loadEndpoints } from "../../../actions/endpointActions";
import { loadEndpointsDevice } from "../../../actions/deviceEndpointsActions";
import { saveDeviceListByMessageType } from "../../../actions/deviceListAction";
import { saveModemRequest } from "../../../actions/deviceModemActions";

//Components
import EndpointModal from "./EndpointModal";
import Spinner from "../../common/Spinner";

// Styling
import "./EndpointsPane.css";

const DeviceEndpointsPane = ({
  auth,
  device,
  endpoints,
  deviceEndpoints,
  isLoading,
}) => {
  const dispatch = useDispatch();

  const [EndpointModalShow, setEndpointModalShow] = useState(false);
  const [SelectedEndpoint, setSelectedEndpoint] = useState({});
  const [SelectedEndpointInfo, setSelectedEndpointInfo] = useState({
    OutputServer: "",
    Name: "",
  });
  const [SelectedProtocol, setSelectedProtocol] = useState("non");
  const [Cantchange, setCantchange] = useState(false);

  useEffect(() => {
    getEndpointsDevices();
  }, []);

  const getEndpointsDevices = () => {
    // Load all endpoints
    dispatch(loadEndpoints(auth));

    // Load endpoints for this device
    dispatch(loadEndpointsDevice(auth, device.IMEI));
  };

  const EnpOnclickhander = (e) => {
    let reqID = e.currentTarget.getAttribute("id");
    let devendpoint = deviceEndpoints.find((a) => a._id == reqID);
    setSelectedEndpoint(devendpoint);
    setSelectedEndpointInfo({ OutputServer: "", Name: "" });
    setSelectedProtocol("non");
    setEndpointModalShow(true);
  };

  const OnSave = (event) => {
    event.preventDefault();
    let request = {
      _id: SelectedEndpoint._id,
      IMEI: SelectedEndpoint.IMEI,
      OutputProtocol: SelectedProtocol,
      EndpointId: SelectedEndpointInfo._id,
      APN: null,
      MessageType: SelectedEndpoint.MessageType,
    };
    dispatch(saveDeviceListByMessageType(auth, request));
    getEndpointsDevices();
    setEndpointModalShow(false);
  };

  const OnRemove = (event) => {
    event.preventDefault();
    removeRequest();
    removeModemRequest();
    getEndpointsDevices();
    setEndpointModalShow(false);
  };

  const removeRequest = () => {
    // Normal Request
    let request = {
      _id: SelectedEndpoint._id,
      IMEI: SelectedEndpoint.IMEI,
      OutputProtocol: "non",
      EndpointId: null,
      APN: null,
      MessageType: SelectedEndpoint.MessageType,
    };
    dispatch(saveDeviceListByMessageType(auth, request));
  };

  const removeModemRequest = () => {
    // MODEM Request
    let modemrequest = {
      IMEI: SelectedEndpoint.IMEI,
      Command: `${SelectedEndpoint.MessageType} http://devices.telematiqs.com:9333/api/Rawmessage xBpfpa2j7y Tele_info T1234 internet.apnconnect.com 0 FF`,
      CommandType: "Web",
    };
    dispatch(saveModemRequest(auth, modemrequest));
  };

  const OnCancel = () => setEndpointModalShow(false);

  return (
    <>
      <EndpointModal
        show={EndpointModalShow}
        selectedEndpoint={SelectedEndpoint}
        onSave={OnSave}
        onRemove={OnRemove}
        onCancel={OnCancel}
        onHide={() => setEndpointModalShow(false)}
        deviceImei={device.IMEI}
        endpoints={endpoints}
        SelectedEndpointInfo={SelectedEndpointInfo}
        setSelectedEndpointInfo={setSelectedEndpointInfo}
        SelectedProtocol={SelectedProtocol}
        setSelectedProtocol={setSelectedProtocol}
        Cantchange={Cantchange}
        setCantchange={setCantchange}
      />
      {!isLoading ? (
        <table className="table table-striped table-hover">
          <thead className="thead-inverse">
            <tr>
              <th>Message</th>
              <th>Protocol</th>
              <th>Name</th>
              <th>Server</th>
              <th>Port</th>
              <th>Username</th>
              <th>Password</th>
              <th>API_KEY</th>
              <th>Authorization</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {deviceEndpoints
              .sort((a, b) => (a.MessageType < b.MessageType ? 1 : -1))
              .map((deviceendpoint) => {
                return (
                  <tr
                    className="table-row"
                    key={deviceendpoint.MessageType}
                    id={deviceendpoint._id}
                    onClick={EnpOnclickhander}
                  >
                    <td>{deviceendpoint.MessageType}</td>
                    <td>{deviceendpoint.OutputProtocol}</td>
                    <td>{deviceendpoint.endpoint.Name}</td>
                    <td>{deviceendpoint.endpoint.OutputServer}</td>
                    <td>{deviceendpoint.endpoint.OutputPort}</td>
                    <td>{deviceendpoint.endpoint.Username}</td>
                    <td>{deviceendpoint.endpoint.Password}</td>
                    <td>{deviceendpoint.endpoint.API_KEY}</td>
                    <td>{deviceendpoint.endpoint.Authorization}</td>
                    <td>{deviceendpoint.endpoint.Status}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <Spinner />
      )}
    </>
  );
};

DeviceEndpointsPane.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  endpoints: PropTypes.array.isRequired,
  deviceEndpoints: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.deviceDetails.device,
    endpoints: state.endpoints,
    deviceEndpoints: state.deviceDetails.endpoints,
    isLoading: state.deviceDetails.isLoading,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceEndpointsPane);
