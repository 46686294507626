import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavItem, Form } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const loginbutton = (auth) => {
  if (auth.isLoggedIn === true) {
    return (
      <NavLink to="/logout" className="nav-link">
        Logout
      </NavLink>
    );
  } else {
    return (
      <NavLink to="/login" className="nav-link">
        Login
      </NavLink>
    );
  }
};
const Header = ({ auth }) => {
  const [showUserMenuItems, setshowUserMenuItems] = useState(false);
  const [showCustomerMenuItems, setshowCustomerMenuItems] = useState(false);
  const [showResellerMenuItems, setshowResellerMenuItems] = useState(false);
  const [showAdminMenuItems, setshowAdminMenuItems] = useState(false);

  useEffect(() => {
    if (auth.isLoggedIn === true) {
      setshowUserMenuItems(
        auth.user.roles.includes("ROLE_USER") ||
          auth.user.roles.includes("ROLE_CUSTOMER")
      );
      setshowCustomerMenuItems(auth.user.roles.includes("ROLE_POWERUSER"));
      setshowResellerMenuItems(auth.user.roles.includes("ROLE_RESELLER"));
      setshowAdminMenuItems(auth.user.roles.includes("ROLE_ADMIN"));
    } else {
      setshowUserMenuItems(false);
      setshowCustomerMenuItems(false);
      setshowResellerMenuItems(false);
      setshowAdminMenuItems(false);
    }
  });

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Navbar.Brand href="/">TelematiQs MiddleWare</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {showUserMenuItems && (
              <>
                <NavItem>
                  {" "}
                  <NavLink to="/" className="nav-link" exact>
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  {" "}
                  <NavLink to="/devices" className="nav-link">
                    Devices
                  </NavLink>
                </NavItem>
                <NavItem>
                  {" "}
                  <NavLink to="/endpoints" className="nav-link">
                    Endpoints
                  </NavLink>
                </NavItem>
              </>
            )}
            {showCustomerMenuItems && (
              <>
                <NavItem>
                  {" "}
                  <NavLink to="/rawmsg" className="nav-link">
                    RawMessages
                  </NavLink>
                </NavItem>
              </>
            )}
            {showResellerMenuItems && (
              <>
                <NavItem>
                  {" "}
                  <NavLink to="/customers" className="nav-link">
                    Customers
                  </NavLink>
                </NavItem>
              </>
            )}
            {showAdminMenuItems && (
              <>
                <NavItem>
                  {" "}
                  <NavLink to="/log" className="nav-link">
                    Log
                  </NavLink>
                </NavItem>
                <NavItem>
                  {" "}
                  <NavLink to="/firmware" className="nav-link">
                    Firmware
                  </NavLink>
                </NavItem>
                <NavItem>
                  {" "}
                  <NavLink to="/canconf" className="nav-link">
                    CAN-CONF
                  </NavLink>
                </NavItem>
                <NavItem>
                  {" "}
                  <NavLink to="/store" className="nav-link">
                    Store
                  </NavLink>
                </NavItem>
                <NavItem>
                  {" "}
                  <NavLink to="/admin" className="nav-link">
                    Admin
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
        <Form inline>
          <Nav className="mr-auto">
            <NavItem> {loginbutton(auth)}</NavItem>
          </Nav>
        </Form>
      </Navbar>
    </>
  );
};

Header.propTypes = {
  auth: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Header);
