import React, { useRef, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import jwt from "jsonwebtoken";

// Time
import moment from "moment";

// Redux
import { connect, useDispatch } from "react-redux";
import { login, logout } from "../../actions/authActions";

// Styling
import "./Login.css";
import { Button, Form, Card, Alert } from "react-bootstrap";

const Login = ({ auth, message, apiCallsInProgress }) => {
  const dispatch = useDispatch();
  const [logingin, setLogingin] = useState(false);
  const [validtoken, setvalidtoken] = useState(false);
  const usernameRef = useRef();
  const passwordRef = useRef();

  const validatetoken = (auth) => {
    if (auth.isLoggedIn === true) {
      var decodedToken = jwt.decode(auth.user.accessToken, { complete: true });
      var current = moment(Math.floor(Date.now() / 1000));
      if (decodedToken.payload.exp - 10 > current) {
        setvalidtoken(true);
        setLogingin(true);
      } else {
        setvalidtoken(false);
        setLogingin(false);
        onLogout();
      }
    }
  };

  useEffect(() => {
    validatetoken(auth);
  }, [auth]);

  const onLogin = (event) => {
    event.preventDefault();
    dispatch(login(usernameRef.current.value, passwordRef.current.value));
  };

  const onLogout = () => {
    dispatch(logout());
    return <Redirect to="/Login" />;
  };

  return logingin === true && validtoken === true ? (
    <Redirect to="/" />
  ) : (
    <div className="login">
      <Card className="login-card">
        <Card.Body>
          <h3 className="text-center mb-4">Log In</h3>
          {message && <Alert variant="danger">{message}</Alert>}
          <Form onSubmit={onLogin}>
            <Form.Group id="email">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="username"
                ref={usernameRef}
                placeholder="Username"
                required
              />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                ref={passwordRef}
                placeholder="Password"
                required
              />
            </Form.Group>
            <Button
              className="w-100 text-center mt-3"
              variant="primary"
              type="Submit"
              disabled={apiCallsInProgress > 0}
            >
              Log In
            </Button>
          </Form>
          {/* <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Forgotten Password?</Link>
          </div> */}
        </Card.Body>
      </Card>
    </div>
  );
};

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    auth: state.auth,
    message: message || "",
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
