import * as types from "./actionsTypes";
import * as configListApi from "../api/configListApi";
import { apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const saveConfigList = (auth, IMEI, config) => async (dispatch) => {
  configListApi
    .saveConfigList(auth, IMEI, config)
    .then((res) => {
      dispatch({
        type: types.SAVE_CONFIGLIST_SUCCESS,
        payload: {
          configlist: res,
        },
      });
      toast.success("Settings saved for " + IMEI);
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const saveConfigTemplateToDevice =
  (auth, device, templateId) => async (dispatch) => {
    configListApi
      .saveConfigTemplateToDevice(auth, device, templateId)
      .then((res) => {
        dispatch({
          type: types.SAVE_CONFIGLIST_SUCCESS,
          payload: {
            configlist: res,
          },
        });
        toast.success("Template settings saved for " + device.IMEI);
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
