import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Components
import DeviceInfoPane from "./deviceInformation/DeviceInfoPane";
import DeviceTestPane from "./installationTest/DeviceTestPane";
import DeviceSettingsPane from "./settings/DeviceSettingsPane";
import DeviceEndpointsPane from "./endpoints/DeviceEndpointsPane";
import DeviceCanConfigPane from "./canConfig/DeviceCanConfigPane";
import DeviceThresholdsPane from "./thresholds/DeviceThresholdsPane";
import DeviceRequestsPane from "./requests/DeviceRequestsPane";
import DeviceRawMessagesPane from "./rawMessage/DeviceRawMessagesPane";
import DeviceConfigHistPane from "./configurationHistory/DeviceConfigHistPane";
import DeviceCustomerHistoryPane from "./customerHistory/DeviceCustomerHistoryPane";

// Styling
import { Tab, Nav, Row, Col, Card } from "react-bootstrap";

const DeviceNavigation = ({ auth, device, ...props }) => {
  useEffect(() => {
    disabledOptions();
  }, []);

  const [currentTab, setCurrentTab] = useState("Information");
  const HandleTab = (key) => {
    setCurrentTab(key);
  };

  const [disableOptions, setDisableOptions] = useState(true);
  const disabledOptions = () => {
    if (
      auth.user.roles.includes("ROLE_RESELLER") ||
      auth.user.roles.includes("ROLE_ADMIN") ||
      auth.user.roles.includes("ROLE_POWERUSER")
    ) {
      setDisableOptions(false);
    } else {
      setDisableOptions(true);
    }
  };

  return (
    <>
      <Card className="card-style device">
        <Card.Header>
          <Card.Title>
            {props.match.params.id === device._id ? (
              <h1 className="device-info-title">Device: {device.IMEI}</h1>
            ) : (
              <h1 className="device-info-title">Device</h1>
            )}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Tab.Container
            id="left-tabs"
            defaultActiveKey="Information"
            onSelect={HandleTab}
          >
            <Row>
              <Col sm={2}>
                <Nav variant="pills" className="flex-column device-nav">
                  <Nav.Item>
                    <Nav.Link eventKey="Information">Information</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Settings">Settings</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Endpoints">Endpoints</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="CANConfig">CAN Config</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ThresholdConfig">Thresholds</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Test">Installation Test</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Requests">Requests</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="RawMessage">RawMessage</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="DataConsumption">
                      Data Consumption
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="History">
                      Configuration History
                    </Nav.Link>
                  </Nav.Item>
                  {!disableOptions && (
                    <Nav.Item>
                      <Nav.Link eventKey="Customer">Customer History</Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
              </Col>
              <Col sm={10}>
                <Tab.Content>
                  <Tab.Pane eventKey="Information">
                    {currentTab == "Information" &&
                    props.match.params.id === device._id ? (
                      <DeviceInfoPane />
                    ) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="Settings">
                    {currentTab == "Settings" ? <DeviceSettingsPane /> : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="Endpoints">
                    {currentTab == "Endpoints" ? <DeviceEndpointsPane /> : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="CANConfig">
                    {currentTab == "CANConfig" ? <DeviceCanConfigPane /> : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="ThresholdConfig">
                    {currentTab == "ThresholdConfig" ? (
                      <DeviceThresholdsPane />
                    ) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="Test">
                    {currentTab == "Test" ? <DeviceTestPane /> : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="Requests">
                    {currentTab == "Requests" ? <DeviceRequestsPane /> : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="RawMessage">
                    {currentTab == "RawMessage" ? (
                      <DeviceRawMessagesPane />
                    ) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="DataConsumption">
                    DataConsumption
                  </Tab.Pane>
                  <Tab.Pane eventKey="History">
                    {currentTab == "History" ? <DeviceConfigHistPane /> : null}
                  </Tab.Pane>
                  {!disableOptions && (
                    <Tab.Pane eventKey="Customer">
                      {currentTab == "Customer" ? (
                        <DeviceCustomerHistoryPane />
                      ) : null}
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Card.Body>
      </Card>
    </>
  );
};

DeviceNavigation.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.deviceDetails.device,
  };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceNavigation);
