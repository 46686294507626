import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import moment from "moment";

// Redux
import { useDispatch, connect } from "react-redux";
import {
  loadModemRequests,
  saveModemRequest,
} from "../../../actions/deviceModemActions";

import { fetchCustomers } from "../../../actions/customerActions";
import { saveConfigTemplateToDevice } from "../../../actions/configListAction";
import { saveDevicesToCustomer } from "../../../actions/deviceCustomerActions";

// Styling
import { Form, Row, Col, Button } from "react-bootstrap";

// Components
import Spinner from "../../common/Spinner";

const DeviceInfoPane = ({ device, auth, isLoading, customers }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [customer, setCustomer] = useState("null");
  const [selectedConfiguration, setSelectedConfiguration] = useState("null");

  useEffect(() => {
    if (
      auth.user.roles.includes("ROLE_RESELLER") ||
      auth.user.roles.includes("ROLE_ADMIN") ||
      auth.user.roles.includes("ROLE_POWERUSER")
    ) {
      dispatch(fetchCustomers(auth));
      if (device.customer.length != 0) {
        setCustomer(device.customer[0]._id);
      }
      setCurrentConfig();
    }
  }, []);

  const timeout = () => {
    setTimeout(() => {
      RefreshRequest();
    }, 1000);
  };

  const OnClickReset = () => {
    let request = {
      IMEI: device.IMEI,
      Command: `RESET ${device.IMEI} 0 FF`,
      CommandType: "Web",
    };
    dispatch(saveModemRequest(auth, request));
    timeout();
  };

  const OnClickConfig = () => {
    let request = {
      IMEI: device.IMEI,
      Command: `$REQUEST,DD FF`,
      CommandType: "Web",
    };
    dispatch(saveModemRequest(auth, request));
    timeout();
  };

  const OnClickreqRT = () => {
    let request = {
      IMEI: device.IMEI,
      Command: `$REQUEST,RT FF`,
      CommandType: "Web",
    };
    dispatch(saveModemRequest(auth, request));
    timeout();
  };

  const OnClickreqTD = () => {
    let request = {
      IMEI: device.IMEI,
      Command: `$REQUEST,TD FF`,
      CommandType: "Web",
    };
    dispatch(saveModemRequest(auth, request));
    timeout();
  };

  const OnClickreqRC = () => {
    let request = {
      IMEI: device.IMEI,
      Command: `$REQUEST,RC FF`,
      CommandType: "Web",
    };
    dispatch(saveModemRequest(auth, request));
    timeout();
  };

  const RefreshRequest = () => {
    dispatch(loadModemRequests(auth, device.IMEI));
  };

  const inputHandler = (e) => {
    setCustomer(e.target.value);
  };

  const inputConfHandler = (e) => {
    setSelectedConfiguration(e.target.value);
    setCurrentConfigId(e.target.value);
  };

  const saveNewInformation = (e) => {
    e.preventDefault();

    if (selectedConfiguration != "null") {
      dispatch(saveConfigTemplateToDevice(auth, device, selectedConfiguration));
      history.go(0);
    }

    if (device.customer.length != 0) {
      if (device.customer[0]._id == customer) {
        return;
      }
    }

    let deviceRequest = {
      start: moment().format("YYYY-MM-DD HH:mm:ss"),
      devices: [device.IMEI],
      id: customer,
    };
    dispatch(saveDevicesToCustomer(auth, deviceRequest));
  };

  const disableOptions = () => {
    return !(
      auth.user.roles.includes("ROLE_POWERUSER") ||
      auth.user.roles.includes("ROLE_RESELLER") ||
      auth.user.roles.includes("ROLE_ADMIN")
    );
  };

  const [currentConfigId, setCurrentConfigId] = useState();

  const setCurrentConfig = () => {
    const current = device.Configs[0];
    const templates = device.configurations;
    templates.forEach((template) => {
      if (shallowEqual(current.SettingsConfig, template.SettingsConfig)) {
        setCurrentConfigId(template._id);
      }
    });
  };

  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }

  return (
    <>
      {!isLoading ? (
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="IMEI">
              <Form.Label>
                <h6>IMEI: </h6>
              </Form.Label>
              <Form.Control plaintext readOnly defaultValue={device.IMEI} />
            </Form.Group>
            <Form.Group as={Col} controlId="SN">
              <Form.Label>
                <h6>Serial Number: </h6>
              </Form.Label>
              <Form.Control
                plaintext
                readOnly
                defaultValue={device.SerialNumber}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="HardwareVersion">
              <Form.Label>
                <h6>HardwareVersion: </h6>
              </Form.Label>
              <Form.Control
                plaintext
                readOnly
                defaultValue={device.HardwareVersion}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="VIN">
              <Form.Label>
                <h6>VIN: </h6>
              </Form.Label>
              <Form.Control plaintext readOnly defaultValue={device.VIN} />
            </Form.Group>
          </Form.Row>
          <Row>
            <Col>
              <Button variant="info" onClick={OnClickConfig}>
                Request Configuration
              </Button>
              {"  "}
              <Button variant="secondary" onClick={OnClickreqRT}>
                Request RT
              </Button>
              {"  "}
              <Button variant="secondary" onClick={OnClickreqTD}>
                Request TD
              </Button>
              {"  "}
              <Button variant="secondary" onClick={OnClickreqRC}>
                Request RC
              </Button>
              {"  "}
              <Button variant="warning" onClick={OnClickReset}>
                Request Reset
              </Button>
            </Col>
            <Col xs lg={12}>
              {" "}
            </Col>
          </Row>
          <hr></hr>
          <h5>Customer Information</h5>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              <h6>Customer:</h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                disabled={disableOptions()}
                as="select"
                value={customer}
                onChange={(e) => inputHandler(e)}
              >
                {auth.user.roles.includes("ROLE_ADMIN") && (
                  <option key="null" value="null">
                    --
                  </option>
                )}
                {customers.map((customer) => {
                  return (
                    <option key={customer._id} value={customer._id}>
                      {customer.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
            <Form.Label column sm="3">
              <h6>Configuration:</h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="select"
                value={currentConfigId}
                disabled={!auth.user.roles.includes("ROLE_ADMIN")}
                onChange={(e) => inputConfHandler(e)}
              >
                <option>--</option>
                {device.configurations.map((config) => {
                  return (
                    <option key={config._id} value={config._id}>
                      {config.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Form.Group>

          <hr></hr>
          <Form.Group as={Row} controlId="formPlaintextPassword">
            <Form.Label column sm="3">
              <h6>
                Booter Version:{" "}
                <i>
                  {device.BooterVersion == device.Configs[0].BooterVersion
                    ? ""
                    : " (" + device.BooterVersion + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control as="select">
                <option>{device.Configs[0].BooterVersion}</option>
              </Form.Control>
            </Col>

            <Form.Label column sm="3">
              <h6>
                CAN1 Version:{" "}
                <i>
                  {device.CANVersion == device.Configs[0].CANVersion
                    ? ""
                    : " (" + device.CANVersion + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control as="select">
                <option>{device.Configs[0].CANVersion}</option>
              </Form.Control>
            </Col>

            <Form.Label column sm="3">
              <h6>
                CAN2 Version:{" "}
                <i>
                  {device.CAN2Version == device.Configs[0].CAN2Version
                    ? ""
                    : " (" + device.CAN2Version + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control as="select">
                <option>{device.Configs[0].CAN2Version}</option>
              </Form.Control>
            </Col>

            <Form.Label column sm="3">
              <h6>
                Configuration Version:{" "}
                <i>
                  {device.ConfigurationVersion ==
                  device.Configs[0].ConfigurationVersion
                    ? ""
                    : " (" + device.ConfigurationVersion + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control as="select">
                <option>{device.Configs[0].ConfigurationVersion}</option>
              </Form.Control>
            </Col>

            <Form.Label column sm="3">
              <h6>Protocol Code:</h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control as="select">
                <option>{device.ProtocolCode}</option>
              </Form.Control>
            </Col>

            <Form.Label column sm="3">
              <h6>
                AddonBoard Version:{" "}
                <i>
                  {device.AddonBoardVersion ==
                  device.Configs[0].AddonBoardVersion
                    ? ""
                    : " (" + device.AddonBoardVersion + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control as="select">
                <option>{device.Configs[0].AddonBoardVersion}</option>
              </Form.Control>
            </Col>

            <Form.Label column sm="3">
              <h6>ICCID:</h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control plaintext readOnly defaultValue={device.ICCID} />
            </Col>

            <Form.Label column sm="3">
              <h6>GSM Number:</h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control defaultValue="N/A" />
            </Col>

            <Form.Label column sm="3">
              <h6>APN:</h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control plaintext readOnly defaultValue={device.APN} />
            </Col>

            <Form.Label column sm="3">
              <h6>GPSMODEMVersion:</h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                plaintext
                readOnly
                defaultValue={device.GPSMODEMVersion}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>Config in sync:</h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                plaintext
                readOnly
                defaultValue={
                  device.Configs[0].DeviceinSync == true ? "Yes" : "No"
                }
              />
            </Col>
          </Form.Group>
          <hr></hr>
          <Form.Group>
            <Row>
              <Col sm="11">
                <Button variant="outline-warning" type="submit">
                  Back
                </Button>
              </Col>
              <Col sm="1">
                <Button
                  variant="success"
                  onClick={(e) => saveNewInformation(e)}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      ) : (
        <Spinner />
      )}
    </>
  );
};

DeviceInfoPane.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.deviceDetails.device,
    customers: state.customers,
    isLoading: state.deviceDetails.isLoading,
  };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInfoPane);
