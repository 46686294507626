import React from "react";
import { BarChart, Bar, XAxis, Tooltip } from "recharts";

const RenderLineChart = (data) => {
  const stats = data.data;

  // eslint-disable-next-line react/prop-types
  function CustomTooltip({ payload, active }) {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{payload[0].payload.date}</p>
          <p className="label">{payload[0].payload.count} messages</p>
        </div>
      );
    }

    return null;
  }

  return (
    <BarChart width={450} height={240} data={stats}>
      <Bar dataKey="count" fill="#0671B8" />
      <XAxis dataKey="name" />
      <Tooltip content={<CustomTooltip />} />
    </BarChart>
  );
};

export default RenderLineChart;
