import React from "react";
import PropTypes from "prop-types";

import {
  Form,
  Button,
  Modal,
  Accordion,
  Card,
  ProgressBar,
  Col,
} from "react-bootstrap";

const ConfigurationModal = ({
  show,
  handleShow,
  handleClose,
  page,
  setPage,
  progress,
  setProgress,
  configuration,
  setConfiguration,
  saveConfigurationHandler,
}) => {
  const inputHandler = (e) => {
    const value = e.target.value;
    setConfiguration({
      ...configuration,
      [e.target.name]: value,
    });
  };

  const settingsInputHandler = (e) => {
    const value = e.target.value;
    setConfiguration({
      ...configuration,
      SettingsConfig: {
        ...configuration.SettingsConfig,
        [e.target.name]: value,
      },
    });
  };

  const canInputHandler = (e) => {
    const value = e.target.value;
    setConfiguration({
      ...configuration,
      CANControlerConfig: {
        ...configuration.CANControlerConfig,
        [e.target.name]: value,
      },
    });
  };

  const thresholdInputHandler = (e) => {
    const value = e.target.value;
    setConfiguration({
      ...configuration,
      ThresholdConfig: {
        ...configuration.ThresholdConfig,
        [e.target.name]: value,
      },
    });
  };

  const backHandler = () => {
    if (page === 0) {
      handleClose();
    } else {
      setPage(page - 1);
      setProgress(progress - 20);
    }
  };

  const forwardHandler = () => {
    if (page === 5) {
      handleClose();
    } else {
      setPage(page + 1);
      setProgress(progress + 20);
    }
  };

  return (
    <div className="create-config">
      <Button className="create-configuration-button" onClick={handleShow}>
        Create Configuration
      </Button>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        className="text-center"
      >
        <Modal.Header closeButton>
          <Modal.Title>Configuration Builder</Modal.Title>
        </Modal.Header>
        <ProgressBar now={progress} />
        <Form>
          {page === 0 && (
            <Modal.Body>
              <h4>Basics</h4>
              <Form.Row>
                <Form.Label column lg={3}>
                  Booter Version
                </Form.Label>
                <Col>
                  <Form.Control
                    name="BooterVersion"
                    defaultValue={configuration.BooterVersion}
                    onChange={inputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  CAN1 Version
                </Form.Label>
                <Col>
                  <Form.Control
                    name="CAN1Version"
                    defaultValue={configuration.CAN1Version}
                    onChange={inputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  CAN2 Version
                </Form.Label>
                <Col>
                  <Form.Control
                    name="CAN2Version"
                    defaultValue={configuration.CAN2Version}
                    onChange={inputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  Configuration Version
                </Form.Label>
                <Col>
                  <Form.Control
                    name="ConfigurationVersion"
                    defaultValue={configuration.ConfigurationVersion}
                    onChange={inputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  Protocol Code
                </Form.Label>
                <Col>
                  <Form.Control
                    name="ProtocolCode"
                    defaultValue={configuration.ProtocolCode}
                    onChange={inputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  AddonBoard Version
                </Form.Label>
                <Col>
                  <Form.Control
                    name="AddonBoardVersion"
                    defaultValue={configuration.AddonBoardVersion}
                    onChange={inputHandler}
                  />
                </Col>
              </Form.Row>
            </Modal.Body>
          )}
          {page === 1 && (
            <Modal.Body>
              <h4>Can Controller</h4>
              <Form.Row>
                <Form.Label column lg={3}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    name="BooterVersion"
                    defaultValue={configuration.CANControlerConfig.Status}
                    onChange={canInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  CAN1baudrate
                </Form.Label>
                <Col>
                  <Form.Control
                    name="CAN1baudrate"
                    defaultValue={configuration.CANControlerConfig.CAN1baudrate}
                    onChange={canInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  CAN1mode
                </Form.Label>
                <Col>
                  <Form.Control
                    name="CAN1mode"
                    defaultValue={configuration.CANControlerConfig.CAN1mode}
                    onChange={canInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  CAN2baudrate
                </Form.Label>
                <Col>
                  <Form.Control
                    name="CAN2baudrate"
                    defaultValue={configuration.CANControlerConfig.CAN2baudrate}
                    onChange={canInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  CAN2mode
                </Form.Label>
                <Col>
                  <Form.Control
                    name="CAN2mode"
                    defaultValue={configuration.CANControlerConfig.CAN2mode}
                    onChange={canInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  Speedcalibrationvalue
                </Form.Label>
                <Col>
                  <Form.Control
                    name="Speedcalibrationvalue"
                    defaultValue={
                      configuration.CANControlerConfig.Speedcalibrationvalue
                    }
                    onChange={canInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  Fuelcalibrationvalue
                </Form.Label>
                <Col>
                  <Form.Control
                    name="Fuelcalibrationvalue"
                    defaultValue={
                      configuration.CANControlerConfig.Fuelcalibrationvalue
                    }
                    onChange={canInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  PTOstatus
                </Form.Label>
                <Col>
                  <Form.Control
                    name="PTOstatus"
                    defaultValue={configuration.CANControlerConfig.PTOstatus}
                    onChange={canInputHandler}
                  />
                </Col>
              </Form.Row>
            </Modal.Body>
          )}
          {page === 2 && (
            <Modal.Body>
              <h4>Thresholds</h4>
              <Form.Row>
                <Form.Label column lg={3}>
                  Harshbrake
                </Form.Label>
                <Col>
                  <Form.Control
                    name="Harshbrake"
                    defaultValue={configuration.ThresholdConfig.Harshbrake}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  Longidle
                </Form.Label>
                <Col>
                  <Form.Control
                    name="Longidle"
                    defaultValue={configuration.ThresholdConfig.Longidle}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  HighAcceleration
                </Form.Label>
                <Col>
                  <Form.Control
                    name="HighAcceleration"
                    defaultValue={
                      configuration.ThresholdConfig.HighAcceleration
                    }
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  OverSpeed
                </Form.Label>
                <Col>
                  <Form.Control
                    name="OverSpeed"
                    defaultValue={configuration.ThresholdConfig.OverSpeed}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  HighRevolutions
                </Form.Label>
                <Col>
                  <Form.Control
                    name="HighRevolutions"
                    defaultValue={configuration.ThresholdConfig.HighRevolutions}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  HighTorque
                </Form.Label>
                <Col>
                  <Form.Control
                    name="HighTorque"
                    defaultValue={configuration.ThresholdConfig.HighTorque}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  HighAccelerationPedal
                </Form.Label>
                <Col>
                  <Form.Control
                    name="HighAccelerationPedal"
                    defaultValue={
                      configuration.ThresholdConfig.HighAccelerationPedal
                    }
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  MediumBrake
                </Form.Label>
                <Col>
                  <Form.Control
                    name="MediumBrake"
                    defaultValue={configuration.ThresholdConfig.MediumBrake}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  MediumAcceleration
                </Form.Label>
                <Col>
                  <Form.Control
                    name="MediumAcceleration"
                    defaultValue={
                      configuration.ThresholdConfig.MediumAcceleration
                    }
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  MediumSpeed
                </Form.Label>
                <Col>
                  <Form.Control
                    name="MediumSpeed"
                    defaultValue={configuration.ThresholdConfig.MediumSpeed}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  MediumRevolutions
                </Form.Label>
                <Col>
                  <Form.Control
                    name="MediumRevolutions"
                    defaultValue={
                      configuration.ThresholdConfig.MediumRevolutions
                    }
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  MediumTorque
                </Form.Label>
                <Col>
                  <Form.Control
                    name="MediumTorque"
                    defaultValue={configuration.ThresholdConfig.MediumTorque}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  MediumAccelerationPedal
                </Form.Label>
                <Col>
                  <Form.Control
                    name="MediumAccelerationPedal"
                    defaultValue={
                      configuration.ThresholdConfig.MediumAccelerationPedal
                    }
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  LowBrake
                </Form.Label>
                <Col>
                  <Form.Control
                    name="LowBrake"
                    defaultValue={configuration.ThresholdConfig.LowBrake}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  LowIdle
                </Form.Label>
                <Col>
                  <Form.Control
                    name="LowIdle"
                    defaultValue={configuration.ThresholdConfig.LowIdle}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  LowAcceleration
                </Form.Label>
                <Col>
                  <Form.Control
                    name="LowAcceleration"
                    defaultValue={configuration.ThresholdConfig.LowAcceleration}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  LowSpeed
                </Form.Label>
                <Col>
                  <Form.Control
                    name="LowSpeed"
                    defaultValue={configuration.ThresholdConfig.LowSpeed}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  LowRevolutions
                </Form.Label>
                <Col>
                  <Form.Control
                    name="LowRevolutions"
                    defaultValue={configuration.ThresholdConfig.LowRevolutions}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  LowTorque
                </Form.Label>
                <Col>
                  <Form.Control
                    name="LowTorque"
                    defaultValue={configuration.ThresholdConfig.LowTorque}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  LowAccelerationPedal
                </Form.Label>
                <Col>
                  <Form.Control
                    name="LowAccelerationPedal"
                    defaultValue={
                      configuration.ThresholdConfig.LowAccelerationPedal
                    }
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  StopSpeed
                </Form.Label>
                <Col>
                  <Form.Control
                    name="StopSpeed"
                    defaultValue={configuration.ThresholdConfig.StopSpeed}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  AccelerationPedalSpeed
                </Form.Label>
                <Col>
                  <Form.Control
                    name="AccelerationPedalSpeed"
                    defaultValue={
                      configuration.ThresholdConfig.AccelerationPedalSpeed
                    }
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  OverspeedTime
                </Form.Label>
                <Col>
                  <Form.Control
                    name="OverspeedTime"
                    defaultValue={configuration.ThresholdConfig.OverspeedTime}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  HighRpmTime
                </Form.Label>
                <Col>
                  <Form.Control
                    name="HighRpmTime"
                    defaultValue={configuration.ThresholdConfig.HighRpmTime}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  CoastingTime
                </Form.Label>
                <Col>
                  <Form.Control
                    name="CoastingTime"
                    defaultValue={configuration.ThresholdConfig.CoastingTime}
                    onChange={thresholdInputHandler}
                  />
                </Col>
              </Form.Row>
            </Modal.Body>
          )}
          {page === 3 && (
            <Modal.Body>
              <h4>Settings</h4>
              <Form.Row>
                <Form.Label column lg={3}>
                  iButton
                </Form.Label>
                <Col>
                  <Form.Check
                    name="iButton"
                    value={configuration.SettingsConfig.iButton}
                    defaultChecked={
                      configuration.SettingsConfig.iButton === "1"
                        ? true
                        : false
                    }
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  PrivateSwitchEnable
                </Form.Label>
                <Col>
                  <Form.Check
                    name="PrivateSwitchEnable"
                    value={configuration.SettingsConfig.PrivateSwitchEnable}
                    defaultChecked={
                      configuration.SettingsConfig.PrivateSwitchEnable === "1"
                        ? true
                        : false
                    }
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  D8Enable
                </Form.Label>
                <Col>
                  <Form.Check
                    name="D8Enable"
                    value={configuration.SettingsConfig.D8Enable}
                    defaultChecked={
                      configuration.SettingsConfig.D8Enable === "1"
                        ? true
                        : false
                    }
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  TachographType
                </Form.Label>
                <Col sm="9">
                  <Form.Check
                    name="TachographType"
                    type="radio"
                    label="VDO"
                    className="radio"
                    defaultChecked={
                      configuration.SettingsConfig.TachographType === "vdo"
                        ? true
                        : false
                    }
                    value="vdo"
                    onChange={settingsInputHandler}
                  />
                  <Form.Check
                    name="TachographType"
                    type="radio"
                    label="Stoneridge"
                    className="radio"
                    defaultChecked={
                      configuration.SettingsConfig.TachographType ===
                      "stoneridge"
                        ? true
                        : false
                    }
                    value="stoneridge"
                    onChange={settingsInputHandler}
                  />
                  <Form.Check
                    name="TachographType"
                    type="radio"
                    label="None"
                    className="radio"
                    defaultChecked={
                      configuration.SettingsConfig.TachographType === "0"
                        ? true
                        : false
                    }
                    value="0"
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>

              <Form.Row>
                <Form.Label column lg={3}>
                  FutureBool1
                </Form.Label>
                <Col>
                  <Form.Control
                    name="FutureBool1"
                    defaultValue={configuration.SettingsConfig.FutureBool1}
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>

              <Form.Row>
                <Form.Label column lg={3}>
                  RTStandbyInterval
                </Form.Label>
                <Col>
                  <Form.Control
                    name="RTStandbyInterval"
                    defaultValue={
                      configuration.SettingsConfig.RTStandbyInterval
                    }
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  RTDatatimeInterval
                </Form.Label>
                <Col>
                  <Form.Control
                    name="RTDatatimeInterval"
                    defaultValue={
                      configuration.SettingsConfig.RTDatatimeInterval
                    }
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  RTDatadistanceInterval
                </Form.Label>
                <Col>
                  <Form.Control
                    name="RTDatadistanceInterval"
                    defaultValue={
                      configuration.SettingsConfig.RTDatadistanceInterval
                    }
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  RCDateTimeInterval
                </Form.Label>
                <Col>
                  <Form.Control
                    name="RCDateTimeInterval"
                    defaultValue={
                      configuration.SettingsConfig.RCDateTimeInterval
                    }
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  RCDataDistanceInterval
                </Form.Label>
                <Col>
                  <Form.Control
                    name="RCDataDistanceInterval"
                    defaultValue={
                      configuration.SettingsConfig.RCDataDistanceInterval
                    }
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  RCDataTriggerMask
                </Form.Label>
                <Col>
                  <Form.Control
                    name="RCDataTriggerMask"
                    defaultValue={
                      configuration.SettingsConfig.RCDataTriggerMask
                    }
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  TDDataTimeInterval
                </Form.Label>
                <Col>
                  <Form.Control
                    name="TDDataTimeInterval"
                    defaultValue={
                      configuration.SettingsConfig.TDDataTimeInterval
                    }
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  TDDataDistanceInterval
                </Form.Label>
                <Col>
                  <Form.Control
                    name="TDDataDistanceInterval"
                    defaultValue={
                      configuration.SettingsConfig.TDDataDistanceInterval
                    }
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  STDDataTriggerMask
                </Form.Label>
                <Col>
                  <Form.Control
                    name="STDDataTriggerMask"
                    defaultValue={
                      configuration.SettingsConfig.STDDataTriggerMask
                    }
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  iButtonClearInterval
                </Form.Label>
                <Col>
                  <Form.Control
                    name="iButtonClearInterval"
                    defaultValue={
                      configuration.SettingsConfig.iButtonClearInterval
                    }
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  FutureInt1
                </Form.Label>
                <Col>
                  <Form.Control
                    name="FutureInt1"
                    defaultValue={configuration.SettingsConfig.FutureInt1}
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={3}>
                  FutureInt2
                </Form.Label>
                <Col>
                  <Form.Control
                    name="FutureInt2"
                    defaultValue={configuration.SettingsConfig.FutureInt2}
                    onChange={settingsInputHandler}
                  />
                </Col>
              </Form.Row>
            </Modal.Body>
          )}
          {page === 4 && (
            <Modal.Body>
              <Form.Row>
                <Form.Label>Type in a name</Form.Label>
                <Form.Control
                  type="name"
                  name="name"
                  value={configuration.name}
                  onChange={inputHandler}
                  placeholder="Type in a name"
                />
              </Form.Row>
            </Modal.Body>
          )}
          {page === 5 && (
            <Modal.Body>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    <h4>Summary</h4>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p>
                        <strong>Name:</strong> {configuration.name}
                      </p>
                      <p>
                        <strong>Created by:</strong> {configuration.createdBy}
                      </p>
                      <p>
                        <strong>Date:</strong> {configuration.createdAt}
                      </p>
                      <p>
                        <strong>Customer ID:</strong> {configuration.customerId}
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Modal.Body>
          )}
        </Form>
        <Modal.Footer>
          <Button onClick={backHandler}>Back</Button>
          {page !== 5 && (
            <Button
              disabled={page === 4 && configuration.name == ""}
              onClick={forwardHandler}
            >
              Continue
            </Button>
          )}
          {page === 5 && (
            <Button onClick={saveConfigurationHandler}>Save</Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

ConfigurationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleShow: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  setProgress: PropTypes.func.isRequired,
  configuration: PropTypes.object.isRequired,
  setConfiguration: PropTypes.func.isRequired,
  saveConfigurationHandler: PropTypes.func.isRequired,
};

export default ConfigurationModal;
