import { combineReducers } from "redux";
import apiCallsInProgress from "./apiStatusReducer";
import devices from "./deviceReducer";
import auth from "./authReducer";
import message from "./messageReducer";
import endpoints from "./endpointReducer";
import customers from "./customerReducer";
import dashboard from "./dashboardReducer";
import deviceDetails from "./deviceDetailsReducer";
import rawMessages from "./rawMessagesReducer";
import logs from "./logsReducer";
import users from "./userReducer";
import customerDetails from "./customerDetailsReducer";
import inputValidation from "./inputValidationReducer";

const rootReducer = combineReducers({
  auth,
  apiCallsInProgress,
  message,
  dashboard,
  devices,
  deviceDetails,
  endpoints,
  customers,
  customerDetails,
  rawMessages,
  logs,
  users,
  inputValidation,
});

export default rootReducer;
