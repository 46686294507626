import * as types from "./actionsTypes";
import * as UserApi from "../api/frontendusersApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const loadCustomerFrontendUsers = (auth, id) => async (dispatch) => {
  dispatch(beginApiCall());

  dispatch({
    type: "LOADING_CUSTOMER_DETAILS",
    payload: {
      isLoading: true,
    },
  });

  UserApi.loadCustomerFrontendUsers(auth, id)
    .then((users) => {
      dispatch({
        type: types.LOAD_CUSTOMER_USERS_SUCCESS,
        payload: {
          users: users,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const loadUsers = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  UserApi.getfrontendusers(auth)
    .then((users) => {
      dispatch({
        type: types.LOAD_USERS_SUCCESS,
        payload: {
          users: users,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const loadRoles = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  UserApi.getroles(auth)
    .then((roles) => {
      dispatch({
        type: types.LOAD_ROLES_SUCCESS,
        payload: {
          roles: roles,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const saveNewUser = (auth, user) => async (dispatch) => {
  UserApi.saveFrontEndUser(auth, user)
    .then((res) => {
      dispatch({
        type: types.SAVE_USER_SUCCESS,
        payload: {
          user: res,
        },
      });
      toast.success("User " + res.username + " succesfully created");
      dispatch(loadUsers(auth));
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const updateNewUser = (auth, user) => async (dispatch) => {
  UserApi.saveFrontEndUser(auth, user)
    .then((res) => {
      if (res.customerId != null) {
        dispatch(loadCustomerFrontendUsers(auth, res.customerId));
      } else {
        dispatch({
          type: types.UPDATE_USER_SUCCESS,
          payload: {
            user: res,
          },
        });
      }
      toast.success("User " + res.username + " succesfully updated");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const removeUser = (auth, id) => async (dispatch) => {
  dispatch(beginApiCall());
  UserApi.removeFrontEndUser(auth, id)
    .then((res) => {
      dispatch({
        type: types.REMOVE_USER_SUCCESS,
        payload: {
          user: res._id,
        },
      });
      toast.error("User " + res._id + " succesfully deleted");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
