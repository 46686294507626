const baseUrl = process.env.API_URL + "/api/auth/signin";
import axios from 'axios';

const login = async function (username, password) {
    const response = await axios
        .post(baseUrl, {
            username,
            password
        });
    if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
};

const logout = () => {
    localStorage.removeItem("user");
};

export default {
    login,
    logout,
};

