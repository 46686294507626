import * as types from "../actions/actionsTypes";
import initialState from './initialState';


export default function (state = initialState.message, action) {
    const { type, payload } = action;

    switch (type) {
        case types.SET_MESSAGE:
            return { message: payload };

        case types.CLEAR_MESSAGE:
            return { message: "" };

        case types.LOGOUT:
            return initialState.message;
        default:
            return state;
    }
}