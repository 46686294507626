import React from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Styling
import { Form, Row, Col, Button } from "react-bootstrap";

// Components
import Spinner from "../../common/Spinner";

const DeviceThresholdsPane = ({ device, isLoading }) => {
  return (
    <>
      {!isLoading ? (
        <Form>
          <Form.Group as={Row} controlId="formThresholdSettings">
            <Form.Label column sm="3">
              <h6>
                Harshbrake:{" "}
                <i>
                  {device.ThresholdConfig.Harshbrake ==
                  device.Configs[0].ThresholdConfig.Harshbrake
                    ? ""
                    : " (" + device.ThresholdConfig.Harshbrake + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.Harshbrake}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                Longidle:{" "}
                <i>
                  {device.ThresholdConfig.Longidle ==
                  device.Configs[0].ThresholdConfig.Longidle
                    ? ""
                    : " (" + device.ThresholdConfig.Longidle + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.Longidle}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                HighAcceleration:{" "}
                <i>
                  {device.ThresholdConfig.HighAcceleration ==
                  device.Configs[0].ThresholdConfig.HighAcceleration
                    ? ""
                    : " (" + device.ThresholdConfig.HighAcceleration + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={
                  device.Configs[0].ThresholdConfig.HighAcceleration
                }
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                OverSpeed:{" "}
                <i>
                  {device.ThresholdConfig.OverSpeed ==
                  device.Configs[0].ThresholdConfig.OverSpeed
                    ? ""
                    : " (" + device.ThresholdConfig.OverSpeed + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.OverSpeed}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                HighRevolutions:{" "}
                <i>
                  {device.ThresholdConfig.HighRevolutions ==
                  device.Configs[0].ThresholdConfig.HighRevolutions
                    ? ""
                    : " (" + device.ThresholdConfig.HighRevolutions + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.HighRevolutions}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                HighTorque:{" "}
                <i>
                  {device.ThresholdConfig.HighTorque ==
                  device.Configs[0].ThresholdConfig.HighTorque
                    ? ""
                    : " (" + device.ThresholdConfig.HighTorque + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.HighTorque}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                HighAccelerationPedal:{" "}
                <i>
                  {device.ThresholdConfig.HighAccelerationPedal ==
                  device.Configs[0].ThresholdConfig.HighAccelerationPedal
                    ? ""
                    : " (" + device.ThresholdConfig.HighAccelerationPedal + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={
                  device.Configs[0].ThresholdConfig.HighAccelerationPedal
                }
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                MediumBrake:{" "}
                <i>
                  {device.ThresholdConfig.MediumBrake ==
                  device.Configs[0].ThresholdConfig.MediumBrake
                    ? ""
                    : " (" + device.ThresholdConfig.MediumBrake + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.MediumBrake}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                MediumIdle:{" "}
                <i>
                  {device.ThresholdConfig.MediumIdle ==
                  device.Configs[0].ThresholdConfig.MediumIdle
                    ? ""
                    : " (" + device.ThresholdConfig.MediumIdle + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.MediumIdle}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                MediumAcceleration:{" "}
                <i>
                  {device.ThresholdConfig.MediumAcceleration ==
                  device.Configs[0].ThresholdConfig.MediumAcceleration
                    ? ""
                    : " (" + device.ThresholdConfig.MediumAcceleration + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={
                  device.Configs[0].ThresholdConfig.MediumAcceleration
                }
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                MediumSpeed:{" "}
                <i>
                  {device.ThresholdConfig.MediumSpeed ==
                  device.Configs[0].ThresholdConfig.MediumSpeed
                    ? ""
                    : " (" + device.ThresholdConfig.MediumSpeed + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.MediumSpeed}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                MediumRevolutions:{" "}
                <i>
                  {device.ThresholdConfig.MediumRevolutions ==
                  device.Configs[0].ThresholdConfig.MediumRevolutions
                    ? ""
                    : " (" + device.ThresholdConfig.MediumRevolutions + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={
                  device.Configs[0].ThresholdConfig.MediumRevolutions
                }
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                MediumTorque:{" "}
                <i>
                  {device.ThresholdConfig.MediumTorque ==
                  device.Configs[0].ThresholdConfig.MediumTorque
                    ? ""
                    : " (" + device.ThresholdConfig.MediumTorque + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.MediumTorque}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                MediumAccelerationPedal:{" "}
                <i>
                  {device.ThresholdConfig.MediumAccelerationPedal ==
                  device.Configs[0].ThresholdConfig.MediumAccelerationPedal
                    ? ""
                    : " (" +
                      device.ThresholdConfig.MediumAccelerationPedal +
                      ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={
                  device.Configs[0].ThresholdConfig.MediumAccelerationPedal
                }
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                LowBrake:{" "}
                <i>
                  {device.ThresholdConfig.LowBrake ==
                  device.Configs[0].ThresholdConfig.LowBrake
                    ? ""
                    : " (" + device.ThresholdConfig.LowBrake + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.LowBrake}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                LowIdle:{" "}
                <i>
                  {device.ThresholdConfig.LowIdle ==
                  device.Configs[0].ThresholdConfig.LowIdle
                    ? ""
                    : " (" + device.ThresholdConfig.LowIdle + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.LowIdle}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                LowAcceleration:{" "}
                <i>
                  {device.ThresholdConfig.LowAcceleration ==
                  device.Configs[0].ThresholdConfig.LowAcceleration
                    ? ""
                    : " (" + device.ThresholdConfig.LowAcceleration + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.LowAcceleration}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                LowSpeed:{" "}
                <i>
                  {device.ThresholdConfig.LowSpeed ==
                  device.Configs[0].ThresholdConfig.LowSpeed
                    ? ""
                    : " (" + device.ThresholdConfig.LowSpeed + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.LowSpeed}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                LowRevolutions:{" "}
                <i>
                  {device.ThresholdConfig.LowRevolutions ==
                  device.Configs[0].ThresholdConfig.LowRevolutions
                    ? ""
                    : " (" + device.ThresholdConfig.LowRevolutions + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.LowRevolutions}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                LowTorque:{" "}
                <i>
                  {device.ThresholdConfig.LowTorque ==
                  device.Configs[0].ThresholdConfig.LowTorque
                    ? ""
                    : " (" + device.ThresholdConfig.LowTorque + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.LowTorque}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                LowAccelerationPedal:{" "}
                <i>
                  {device.ThresholdConfig.LowAccelerationPedal ==
                  device.Configs[0].ThresholdConfig.LowAccelerationPedal
                    ? ""
                    : " (" + device.ThresholdConfig.LowAccelerationPedal + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={
                  device.Configs[0].ThresholdConfig.LowAccelerationPedal
                }
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                StopSpeed:{" "}
                <i>
                  {device.ThresholdConfig.StopSpeed ==
                  device.Configs[0].ThresholdConfig.StopSpeed
                    ? ""
                    : " (" + device.ThresholdConfig.StopSpeed + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.StopSpeed}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                AccelerationPedalSpeed:{" "}
                <i>
                  {device.ThresholdConfig.AccelerationPedalSpeed ==
                  device.Configs[0].ThresholdConfig.AccelerationPedalSpeed
                    ? ""
                    : " (" +
                      device.ThresholdConfig.AccelerationPedalSpeed +
                      ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={
                  device.Configs[0].ThresholdConfig.AccelerationPedalSpeed
                }
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                OverspeedTime:{" "}
                <i>
                  {device.ThresholdConfig.OverspeedTime ==
                  device.Configs[0].ThresholdConfig.OverspeedTime
                    ? ""
                    : " (" + device.ThresholdConfig.OverspeedTime + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.OverspeedTime}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                HighRpmTime:{" "}
                <i>
                  {device.ThresholdConfig.HighRpmTime ==
                  device.Configs[0].ThresholdConfig.HighRpmTime
                    ? ""
                    : " (" + device.ThresholdConfig.HighRpmTime + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.HighRpmTime}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                CoastingTime:{" "}
                <i>
                  {device.ThresholdConfig.CoastingTime ==
                  device.Configs[0].ThresholdConfig.CoastingTime
                    ? ""
                    : " (" + device.ThresholdConfig.CoastingTime + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.CoastingTime}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                ConfigGroupId:{" "}
                <i>
                  {device.ThresholdConfig.ConfigGroupId ==
                  device.Configs[0].ThresholdConfig.ConfigGroupId
                    ? ""
                    : " (" + device.ThresholdConfig.ConfigGroupId + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={device.Configs[0].ThresholdConfig.ConfigGroupId}
              />
            </Col>
            <Form.Label column sm="3">
              <h6>
                ConfigGroupVersion:{" "}
                <i>
                  {device.ThresholdConfig.ConfigGroupVersion ==
                  device.Configs[0].ThresholdConfig.ConfigGroupVersion
                    ? ""
                    : " (" + device.ThresholdConfig.ConfigGroupVersion + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                defaultValue={
                  device.Configs[0].ThresholdConfig.ConfigGroupVersion
                }
              />
            </Col>
          </Form.Group>
          <hr></hr>
          <Form.Group>
            <Row>
              <Col sm="11">
                <Button variant="outline-warning" type="submit">
                  Back
                </Button>
              </Col>
              <Col sm="1">
                <Button variant="success" type="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      ) : (
        <Spinner />
      )}
    </>
  );
};

DeviceThresholdsPane.propTypes = {
  device: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    device: state.deviceDetails.device,
    isLoading: state.deviceDetails.isLoading,
  };
}
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceThresholdsPane);
