import * as types from "./actionsTypes";
import * as customerApi from "../api/customerApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const loadCustomerInfo = (auth, id) => async (dispatch) => {
  dispatch(beginApiCall());

  dispatch({
    type: "LOADING_CUSTOMER_DETAILS",
    payload: {
      isLoading: true,
    },
  });

  customerApi
    .getCustomerById(auth, id)
    .then((customer) => {
      dispatch({
        type: types.LOAD_CUSTOMER_DETAILS_SUCCESS,
        payload: {
          customer: customer,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const fetchCustomers = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  customerApi
    .getCustomers(auth)
    .then((customers) => {
      dispatch({
        type: types.LOAD_CUSTOMERS_SUCCESS,
        payload: {
          customers: customers,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const saveCustomer = (auth, customer) => async (dispatch) => {
  customerApi
    .saveCustomer(customer, auth)
    .then((res) => {
      dispatch({
        type: types.SAVE_CUSTOMER_SUCCESS,
        payload: {
          customer: res,
        },
      });
      toast.success("Customer " + res.name + " succesfully created");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const updateCustomer = (auth, customer) => async (dispatch) => {
  customerApi
    .saveCustomer(customer, auth)
    .then((res) => {
      dispatch({
        type: types.UPDATE_CUSTOMER_SUCCESS,
        payload: {
          customer: res,
        },
      });
      toast.success("Customer " + res.name + " succesfully updated!");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const removeCustomer = (auth, id) => async (dispatch) => {
  customerApi
    .removeCustomer(auth, id)
    .then((res) => {
      dispatch({
        type: types.REMOVE_CUSTOMER_SUCCESS,
        payload: {
          customer: res._id,
        },
      });
      toast.warning("Customer " + res.name + " succesfully deactivated");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export default fetchCustomers;
