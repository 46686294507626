import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { connect, useDispatch } from "react-redux";
import { loadAllRawMessages } from "../../actions/rawMessagesActions";

// Components
import RawmessageList from "./RawmessageList";
import Spinner from "../common/Spinner";

// Styling
import "./Rawmessages.css";
import { Button, Card, Form } from "react-bootstrap";

const RawmessagePage = ({ auth, rawMessages, apiCallsInProgress }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    dispatch(loadAllRawMessages(auth));
  }, []);

  const refreshData = () => {
    dispatch(loadAllRawMessages(auth));
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const result = rawMessages.filter((Rawmessage) =>
      Rawmessage[filter].toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    setSearchResult(result);
  }, [searchTerm, rawMessages, filter]);

  const [filter, setFilter] = useState("RawMessage");

  const filterHandler = (e) => {
    setFilter(e.target.value);
  };

  return (
    <>
      <h2 className="title">Raw Messages</h2>
      <div className="raw-messages">
        <Card className="search">
          <div className="search">
            <Form.Control
              className="search-select-messages"
              as="select"
              name="customer"
              value={filter}
              onChange={filterHandler}
            >
              <option key={"RawMessage"} value={"RawMessage"}>
                RawMessage
              </option>
              <option key={"Timestamp"} value={"Timestamp"}>
                Timestamp
              </option>
            </Form.Control>
            <input
              className="search-input"
              type="text"
              placeholder="Search messages"
              value={searchTerm}
              onChange={handleChange}
            />
            <div className="result">
              <b>Results : </b>
              {searchResult.length > 0
                ? " " + searchResult.length
                : " " + rawMessages.length}
            </div>
            <Button
              className="refresh-border"
              variant="light"
              onClick={refreshData}
            >
              ⟳
            </Button>
          </div>
        </Card>
      </div>
      <br />
      <Card>
        {(rawMessages.length === 0) | (apiCallsInProgress > 0) ? (
          <Spinner />
        ) : (
          <>
            {searchTerm.length === 0 ? (
              <RawmessageList rawMessages={rawMessages} />
            ) : (
              <RawmessageList rawMessages={searchResult} />
            )}
          </>
        )}
      </Card>
    </>
  );
};

RawmessagePage.propTypes = {
  auth: PropTypes.object.isRequired,
  rawMessages: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    rawMessages: state.rawMessages,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RawmessagePage);
