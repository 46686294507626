import React from "react";
import PropTypes from "prop-types";

const DevicesFooter = ({ selected }) => {
  return (
    <div className="footer">
      <p className="footer-devices-text">
        {selected.length} {selected.length > 1 ? "devices" : "device"} selected
      </p>
    </div>
  );
};

DevicesFooter.propTypes = {
  selected: PropTypes.array.isRequired,
};

export default DevicesFooter;
