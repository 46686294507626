import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const deviceDetailsReducer = (state = initialState.deviceDetails, action) => {
  switch (action.type) {
    case "LOADING_DEVICE":
      return {
        ...state,
        isLoading: true,
      };
    case types.LOAD_DEVICE_DETAILS_SUCCESS:
      return {
        ...state,
        device: action.payload.device,
        isLoading: false,
      };
    case types.LOAD_DEVICE_ENDPOINTS_SUCCESS:
      return {
        ...state,
        endpoints: action.payload.endpoints,
        isLoading: false,
      };
    case types.LOAD_DEVICE_STATE_SUCCESS:
      return {
        ...state,
        state: action.payload.deviceState,
        isLoading: false,
      };

    case types.LOAD_DEVICE_MODEM_REQUESTS_SUCCESS:
      return {
        ...state,
        modemRequests: action.payload.modemRequests,
        isLoading: false,
      };
    case types.LOAD_DEVICE_RAW_MESSAGES_SUCCESS:
      return {
        ...state,
        rawMessages: action.payload.rawMessages,
        isLoading: false,
      };
    case types.LOAD_DEVICE_CUSTOMER_HISTORY_SUCCESS:
      return {
        ...state,
        customerHistory: action.payload.customerHistory,
      };
    default:
      return state;
  }
};

export default deviceDetailsReducer;
