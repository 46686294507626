import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

// Styling
import "./Dashboard.css";
import Spinner from "../../common/Spinner";
import { Card, ListGroup, ListGroupItem, CardDeck } from "react-bootstrap";

// Components
import RenderLineChart from "./RenderLineChart";

const Dashboard = ({ dashboard, apiCallsInProgress }) => {
  return (
    <>
      {apiCallsInProgress > 0 ? (
        <Spinner />
      ) : (
        <div className="dashboard">
          <CardDeck>
            <Card>
              <Card.Header>
                <Card.Title>Stats</Card.Title>
              </Card.Header>
              <Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    Devices : <b>{dashboard.deviceCount}</b>
                  </ListGroupItem>
                  <ListGroupItem>
                    Endpoints : <b>{dashboard.endpoints}</b>
                  </ListGroupItem>
                  <ListGroupItem>
                    Messages : <b> {dashboard.rawMessages}</b>
                  </ListGroupItem>
                  {dashboard.logs > 0 && (
                    <ListGroupItem>
                      Logs : <b>{dashboard.logs}</b>
                    </ListGroupItem>
                  )}
                  <ListGroupItem>
                    Last message :{" "}
                    <b>
                      {moment(dashboard.lastMsg).format("DD-MM-YYYY HH:mm:ss")}
                    </b>
                  </ListGroupItem>
                  <ListGroupItem>
                    Age last message : <b>{dashboard.msgAge} Seconds </b>{" "}
                  </ListGroupItem>
                </ListGroup>
              </Card.Body>
            </Card>

            <Card>
              <Card.Header>
                <Card.Title>Message Count</Card.Title>
              </Card.Header>
              <Card.Body>
                {dashboard.weekly == 0 && (
                  <p>Your device message count will be shown here.</p>
                )}
                {dashboard.weekly != 0 && (
                  <RenderLineChart data={dashboard.weekly} />
                )}
              </Card.Body>
            </Card>
          </CardDeck>
        </div>
      )}
    </>
  );
};

Dashboard.propTypes = {
  dashboard: PropTypes.object.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

export default Dashboard;
