import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Styling
import { Modal, Button, Form, Container, Row, Col } from "react-bootstrap";

const EndpointModal = ({
  show,
  onCancel,
  selectedEndpoint,
  endpoints,
  onRemove,
  onSave,
  deviceImei,
  SelectedEndpointInfo,
  setSelectedEndpointInfo,
  SelectedProtocol,
  setSelectedProtocol,
  Cantchange,
  setCantchange,
}) => {
  let endpointselectionkey = "None";
  let Protocollist = [];

  if (selectedEndpoint.MessageType != "RT") {
    Protocollist = ["http", "Forward", "non"];
  } else {
    Protocollist = ["http", "Forward", "Cello", "non"];
  }

  if (selectedEndpoint.MessageType == "DD") {
    setCantchange(true);
    Protocollist = ["non"];
  }

  if (
    selectedEndpoint.endpoint != undefined &&
    selectedEndpoint.endpoint.Name != null &&
    SelectedEndpointInfo.OutputServer == ""
  ) {
    endpointselectionkey = selectedEndpoint.endpoint._id;
    //setSelectedProtocol(selectedEndpoint.OutputProtocol);
    setSelectedEndpointInfo(selectedEndpoint.endpoint);
    if (SelectedEndpointInfo.OutputServer == "" || SelectedProtocol == "non") {
      setCantchange(true);
    }
  }

  const handleChangeEndpointselector = (event) => {
    let selectedEndpoint = endpoints.find((a) => a._id == event.target.value);
    if (selectedEndpoint == null) {
      setSelectedEndpointInfo({ OutputServer: "None", Name: "" });
      setCantchange(true);
    } else {
      setSelectedEndpointInfo(selectedEndpoint);
      if (SelectedProtocol == "non") {
        setCantchange(true);
      } else {
        setCantchange(false);
      }
    }
  };

  const handleChangeProtocolselector = (eventProtocol) => {
    setSelectedProtocol(eventProtocol.target.value);
    if (eventProtocol.target.value == "non") {
      setCantchange(true);
    } else {
      if (SelectedEndpointInfo.Name != "") {
        setCantchange(false);
      }
    }
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onCancel}
    >
      <Form>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {deviceImei}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label>
                  <h6>Message Type: </h6>
                </Form.Label>
              </Col>
              <Col xs={6} md={4}>
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={selectedEndpoint.MessageType}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label>
                  <h6>Protocol : </h6>
                </Form.Label>
              </Col>
              <Col xs={6} md={4}>
                <Form.Control
                  as="select"
                  defaultValue={selectedEndpoint.OutputProtocol}
                  onChange={handleChangeProtocolselector}
                >
                  {Protocollist.map((protocol) => {
                    return <option key={protocol}>{protocol}</option>;
                  })}
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label>
                  <h6>Endpoint : </h6>
                </Form.Label>
              </Col>
              <Col xs={6} md={4}>
                <Form.Control
                  as="select"
                  defaultValue={endpointselectionkey}
                  onChange={handleChangeEndpointselector}
                >
                  <option key="None">None</option>
                  {endpoints.map((endpoint) => {
                    return (
                      <option key={endpoint._id} value={endpoint._id}>
                        {endpoint.Name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
            </Row>
            {SelectedEndpointInfo.OutputServer !== "" &&
            SelectedEndpointInfo.OutputServer !== "None" ? (
              <>
                <br />
                <Row>
                  <Col xs={12} md={4}>
                    <h6>IP Address :</h6>{" "}
                  </Col>
                  <Col xs={6} md={4}>
                    {SelectedEndpointInfo.OutputServer}
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={4}>
                    <h6>Port :</h6>{" "}
                  </Col>
                  <Col xs={6} md={4}>
                    {SelectedEndpointInfo.OutputPort}
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={4}>
                    <h6>Status :</h6>{" "}
                  </Col>
                  <Col xs={6} md={4}>
                    {SelectedEndpointInfo.Status}
                  </Col>
                </Row>
              </>
            ) : (
              <div></div>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            onClick={onRemove}
            disabled={
              selectedEndpoint.OutputProtocol !== "non" &&
              SelectedEndpointInfo.OutputServer !== "None"
                ? false
                : true
            }
            variant="danger"
            type="submit"
          >
            Remove
          </Button>
          <Button
            onClick={onSave}
            disabled={Cantchange}
            variant="success"
            type="submit"
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EndpointModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedEndpoint: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  endpoints: PropTypes.array.isRequired,
  deviceImei: PropTypes.string.isRequired,
  SelectedEndpointInfo: PropTypes.object.isRequired,
  setSelectedEndpointInfo: PropTypes.func.isRequired,
  SelectedProtocol: PropTypes.string.isRequired,
  setSelectedProtocol: PropTypes.func.isRequired,
  Cantchange: PropTypes.bool.isRequired,
  setCantchange: PropTypes.func.isRequired,
};

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EndpointModal);
