import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux";
import { checkValidUser } from "../../../actions/inputValidationActions";

// Styling
import { Form, Button, Modal } from "react-bootstrap";

const CreateUserForCustomerModal = ({
  auth,
  show,
  setShow,
  saveUser,
  newUser,
  setNewUser,
  validName,
}) => {
  const [validForm, setValidForm] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const inputHandler = (e) => {
    const value = e.target.value;
    setNewUser({
      ...newUser,
      [e.target.name]: value,
    });
  };

  const validateUsername = () => {
    dispatch(checkValidUser(auth, newUser.username));
  };

  useEffect(() => {
    let validEmail;
    let validUsername;
    let validPassword;

    if (newUser.username.length >= 3) {
      validUsername = true;
    } else {
      validUsername = false;
    }

    if (newUser.password.length >= 6) {
      validPassword = true;
    } else {
      validPassword = false;
    }

    if (
      newUser.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      validEmail = true;
    } else {
      validEmail = false;
    }

    if (
      validEmail === true &&
      validUsername === true &&
      validName == true &&
      validPassword == true
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [newUser, validName]);

  return (
    <div className="create-user">
      <Button onClick={handleShow} style={{ float: "right" }}>
        Add user
      </Button>
      <Modal show={show} onHide={handleClose} centered className="text-center">
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="text-left">
            <Form.Row>
              <Form.Label>Username</Form.Label>
              <Form.Control
                placeholder="Enter Username"
                type="text"
                name="username"
                onBlur={validateUsername}
                value={newUser.username}
                onChange={inputHandler}
                className={validName ? "" : "input-field-invalid"}
              />
              {!validName && (
                <p className="text-invalid">
                  <small> This username is already taken.</small>
                </p>
              )}
            </Form.Row>
            <Form.Row>
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder="Enter email"
                type="text"
                name="email"
                value={newUser.email}
                onChange={inputHandler}
              />
            </Form.Row>
            <Form.Row>
              <Form.Label>Password</Form.Label>
              <Form.Control
                placeholder="Enter password"
                type="password"
                name="password"
                value={newUser.password}
                onChange={inputHandler}
              />
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={!validForm}
            onClick={saveUser}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateUserForCustomerModal;

CreateUserForCustomerModal.propTypes = {
  auth: PropTypes.object.isRequired,
  validName: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  newUser: PropTypes.object.isRequired,
  setNewUser: PropTypes.func.isRequired,
};
