import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/devicelist/";

export function setdevicelistbymessagetype(auth, request) {
    return fetch(baseUrl, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            'x-access-token': auth.user.accessToken
        },
        body: JSON.stringify(request)
    })
        .then(handleResponse)
        .catch(handleError);
}
