import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Redux
import { useDispatch, connect } from "react-redux";
import { loadDevices } from "../../actions/deviceActions";
import { fetchCustomers } from "../../actions/customerActions";
import { saveDevicesToCustomer } from "../../actions/deviceCustomerActions";

// Components
import DeviceList from "./DeviceList";

// Styling
import "./DevicesPage.css";
import "./DevicesPage.scss";

import Spinner from "../common/Spinner";
import { Card, Button, Form } from "react-bootstrap";
import DevicesFooter from "./DevicesFooter";

const DevicesPage = ({ auth, deviceList, apiCallsInProgress }) => {
  const dispatch = useDispatch();
  const devices = [...deviceList];
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [filter, setFilter] = useState("IMEI");

  const filterHandler = (e) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    dispatch(loadDevices(auth));
    if (
      auth.user.roles.includes("ROLE_RESELLER") ||
      auth.user.roles.includes("ROLE_ADMIN")
    ) {
      dispatch(fetchCustomers(auth));
    }
    disabledOptions();
  }, []);

  // refresh data
  const refreshData = () => {
    dispatch(loadDevices(auth));
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchTerm === "") {
      setSearchResult(devices);
    } else {
      if (filter == "customer") {
        const result = devices.filter((devices) => {
          if (devices.customer.length > 0) {
            return devices.customer[0].name
              .toLowerCase()
              .includes(searchTerm.toLocaleLowerCase());
          }
        });
        setSearchResult(result);
      } else {
        const result = devices.filter((devices) =>
          devices[filter].toLowerCase().includes(searchTerm.toLocaleLowerCase())
        );
        setSearchResult(result);
      }
    }
  }, [searchTerm, devices, filter]);

  const [selected, setSelected] = useState([]);
  const [singleSelect, setSingleSelect] = useState("");

  const [show, setShow] = useState(false);
  const [customer, setCustomer] = useState("null");

  const saveDevices = () => {
    let devicesRequest = {
      start: moment().format("YYYY-MM-DD HH:mm:ss"),
      devices: selected,
      id: customer,
    };
    dispatch(saveDevicesToCustomer(auth, devicesRequest));
    handleClose();
  };

  const handleClose = () => {
    setShow(false);
    setCustomer("null");
    setSelected([]);
    setSingleSelect("");
  };

  const removeSelectOnOutsideclick = (e) => {
    if (e.target.id !== "") {
      setSelected([]);
      setSingleSelect("");
    }
  };

  const [disableOptions, setDisableOptions] = useState(true);
  const disabledOptions = () => {
    if (
      auth.user.roles.includes("ROLE_RESELLER") ||
      auth.user.roles.includes("ROLE_ADMIN") ||
      auth.user.roles.includes("ROLE_POWERUSER")
    ) {
      setDisableOptions(false);
    } else {
      setDisableOptions(true);
    }
  };

  return (
    <div
      className="devices"
      id="devices-list-page"
      onClick={removeSelectOnOutsideclick}
    >
      <h2 className="title">Devices</h2>
      <Card>
        <div className="search">
          <Form.Control
            className="search-select"
            as="select"
            name="customer"
            value={filter}
            onChange={filterHandler}
          >
            <option key={"IMEI"} value={"IMEI"}>
              IMEI
            </option>
            <option key={"SerialNumber"} value={"SerialNumber"}>
              SN
            </option>
            <option key={"customer"} value={"customer"}>
              Customer
            </option>

            <option key={"HardwareVersion"} value={"HardwareVersion"}>
              Hardware
            </option>
            <option key={"CANVersion"} value={"CANVersion"}>
              CAN
            </option>
            <option key={"CAN2Version"} value={"CAN2Version"}>
              CAN2
            </option>
            <option key={"ConfigurationVersion"} value={"ConfigurationVersion"}>
              Conf
            </option>
            <option key={"ICCID"} value={"ICCID"}>
              ICCID
            </option>
          </Form.Control>
          <input
            className="search-input"
            type="text"
            placeholder="Search devices"
            value={searchTerm}
            onChange={handleChange}
          />
          <div className="result">
            <b>Results : </b>
            {searchResult.length > 0
              ? " " + searchResult.length
              : " " + devices.length}
          </div>

          <Button
            className="refresh-border"
            variant="light"
            onClick={refreshData}
          >
            ⟳
          </Button>
        </div>
      </Card>
      <br />
      {devices.length === 0 && apiCallsInProgress > 0 ? (
        <Spinner />
      ) : (
        <Card>
          {searchTerm.length === 0 ? (
            <DeviceList
              disableOptions={disableOptions}
              auth={auth}
              devices={devices}
              selected={selected}
              setSelected={setSelected}
              singleSelect={singleSelect}
              setSingleSelect={setSingleSelect}
              show={show}
              setShow={setShow}
              customer={customer}
              setCustomer={setCustomer}
              saveDevices={saveDevices}
            />
          ) : (
            <DeviceList
              disableOptions={disableOptions}
              auth={auth}
              devices={searchResult}
              selected={selected}
              setSelected={setSelected}
              singleSelect={singleSelect}
              setSingleSelect={setSingleSelect}
              show={show}
              setShow={setShow}
              customer={customer}
              setCustomer={setCustomer}
              saveDevices={saveDevices}
            />
          )}
        </Card>
      )}
      {selected.length != 0 ? <DevicesFooter selected={selected} /> : null}
    </div>
  );
};

DevicesPage.propTypes = {
  auth: PropTypes.object.isRequired,
  deviceList: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    deviceList: state.devices,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesPage);
