import * as devicelistApi from "../api/devicelistApi";
import { apiCallError } from "./apiStatusActions";

export const saveDeviceListByMessageType = (auth, request) => async (
  dispatch
) => {
  devicelistApi
    .setdevicelistbymessagetype(auth, request)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
