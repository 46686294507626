import React from "react";
import PropTypes from "prop-types";

// Time
import moment from "moment";

// Redux
import { connect } from "react-redux";

// Components
import Spinner from "../../common/Spinner";

const DeviceConfigHistPane = ({ device, isLoading }) => {
  return (
    <>
      {!isLoading ? (
        <table className="table table-striped table-hover">
          <thead className="thead-inverse">
            <tr>
              <th>Proccesed</th>
              <th>DeviceInSync</th>
              <th>Timestamp</th>
              <th>User</th>
            </tr>
          </thead>
          <tbody>
            {device.Configs.map((configs) => {
              return (
                <tr className="table-row" key={configs._id}>
                  <td>{configs.Proccesed === true ? "Yes" : ""}</td>
                  <td>{configs.DeviceinSync === true ? "Yes" : ""}</td>
                  <td>
                    {moment(configs.Timestamp).format("DD-MM-YYYY HH:mm:ss")}
                  </td>
                  <td>{configs.User}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Spinner />
      )}
    </>
  );
};

DeviceConfigHistPane.propTypes = {
  device: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    device: state.deviceDetails.device,
    isLoading: state.deviceDetails.isLoading,
  };
}
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceConfigHistPane);
