import React from "react";
import PropTypes from "prop-types";

// Time
import moment from "moment";

const LogList = ({ logs }) => (
  <>
    <table className="table table-striped table-hover">
      <thead className="thead-inverse">
        <tr>
          <th className="timeStamp-table-cell">TimeStamp</th>
          <th className="log-table-cell">Log</th>
          <th>Level</th>
        </tr>
      </thead>
      <tbody>
        {logs.length === 0 ? (
          <tr>
            <td colSpan="12" style={{ textAlign: "center" }}>
              No logs found
            </td>
          </tr>
        ) : (
          logs.map((log) => {
            return (
              <tr className="table-row" key={log._id}>
                <td className="timeStamp-table-cell">
                  {moment(log.Timestamp).format("DD-MM-YYYY HH:mm:ss")}
                </td>
                <td className="log-table-cell">{log.Text}</td>
                <td>{log.Level}</td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  </>
);

LogList.propTypes = {
  logs: PropTypes.array.isRequired,
};

export default LogList;
