import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const userReducer = (state = initialState.users, action) => {
  switch (action.type) {
    case types.LOAD_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
      };
    case types.LOAD_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.roles,
      };
    case types.SAVE_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload.user],
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: [
          ...state.users.filter((user) => user._id != action.payload.user._id),
          action.payload.user,
        ],
      };
    case types.REMOVE_USER_SUCCESS:
      return {
        ...state,
        users: [
          ...state.users.filter((user) => user._id != action.payload.user),
        ],
      };
    default:
      return state;
  }
};

export default userReducer;
