import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { connect, useDispatch } from "react-redux";
import {
  loadUsers,
  loadRoles,
  saveNewUser,
  removeUser,
} from "../../actions/userActions";

// Components
import Spinner from "../common/Spinner";
import UserTable from "./UserTable";
import CreateUserModal from "./CreateUserModal";
import "./Admin.css";

import { Card, Button, Form } from "react-bootstrap";

const AdminPage = ({ auth, users, roles, validName }) => {
  const dispatch = useDispatch();
  const userList = [...users];
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  // Modal
  const [show, setShow] = useState(false);
  const [newUser, setNewUser] = useState({
    username: "",
    email: "",
    password: "",
    roles: [],
  });

  useEffect(() => {
    dispatch(loadUsers(auth));
    dispatch(loadRoles(auth));
  }, []);

  const refreshData = () => {
    setSearchTerm("");
    dispatch(loadUsers(auth));
    dispatch(loadRoles(auth));
  };

  useEffect(() => {
    if (searchTerm === "") {
      setSearchResult(userList);
    } else {
      const result = userList.filter((userList) =>
        userList[filter].toLowerCase().includes(searchTerm.toLocaleLowerCase())
      );
      setSearchResult(result);
    }
  }, [searchTerm, userList, filter]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const saveUser = (e) => {
    e.preventDefault();
    dispatch(saveNewUser(auth, newUser));
    setShow(false);
    setNewUser({
      username: "",
      email: "",
      password: "",
      roles: [],
    });
  };

  const removeUserHandler = (user) => {
    if (!user._id) {
      alert("Oops, something went wrong.");
      return;
    }
    if (auth.user.id == user._id) {
      alert("You can't delete yourself!");
      return;
    }
    dispatch(removeUser(auth, user._id));
  };

  const [filter, setFilter] = useState("username");

  const filterHandler = (e) => {
    setFilter(e.target.value);
  };

  return users.length === 0 && roles.length === 0 ? (
    <Spinner />
  ) : (
    <>
      <h2 className="title">Admin</h2>
      <Card>
        <div className="search">
          <Form.Control
            className="search-select"
            as="select"
            name="customer"
            value={filter}
            onChange={filterHandler}
          >
            <option key={"username"} value={"username"}>
              Username
            </option>
            <option key={"email"} value={"email"}>
              Email
            </option>
          </Form.Control>
          <input
            className="search-input"
            type="text"
            placeholder="Search users"
            value={searchTerm}
            onChange={handleChange}
          />
          <div className="result">
            <b>Results : </b>
            {searchResult.length > 0
              ? " " + searchResult.length
              : " " + userList.length}
          </div>
          <Button className="refresh" variant="light" onClick={refreshData}>
            ⟳
          </Button>
        </div>
      </Card>
      <div className="create-user-div">
        <CreateUserModal
          auth={auth}
          show={show}
          setShow={setShow}
          newUser={newUser}
          setNewUser={setNewUser}
          roles={roles}
          saveUser={saveUser}
          validName={validName}
        />
      </div>
      <br />
      <Card>
        {searchTerm.length === 0 ? (
          <UserTable
            auth={auth}
            users={users}
            roles={roles}
            removeUserHandler={removeUserHandler}
            customer={false}
          />
        ) : (
          <UserTable
            auth={auth}
            roles={roles}
            users={searchResult}
            removeUserHandler={removeUserHandler}
            customer={false}
          />
        )}
      </Card>
    </>
  );
};

AdminPage.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  validName: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    users: state.users.users,
    roles: state.users.roles,
    validName: state.inputValidation.validName,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
