import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { checkValidUser } from "../../actions/inputValidationActions";

// Styling
import { Form, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

const CreateUserModal = ({
  auth,
  show,
  setShow,
  saveUser,
  roles,
  newUser,
  setNewUser,
  validName,
}) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validForm, setValidForm] = useState(false);
  const dispatch = useDispatch();

  const validateUsername = () => {
    dispatch(checkValidUser(auth, newUser.username));
  };

  const inputHandler = (e) => {
    let value = "";
    if (e.target.name === "username") {
      value = e.target.value.replace(/[^\w\s]/gi, "");
    } else {
      value = e.target.value;
    }
    setNewUser({
      ...newUser,
      [e.target.name]: value,
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {
        "Make sure your username consists of more than 3 characters and you've entered a valid email. A strong password is also required."
      }
    </Tooltip>
  );

  const roleHandler = (e) => {
    if (e.target.checked === true) {
      // Pak huidige array
      let addedRoles = [...newUser.roles, e.target.name];
      // Add deze nieuwe
      setNewUser({
        ...newUser,
        roles: addedRoles,
      });
    } else {
      // Pak huidige array
      let removeRoles = [...newUser.roles];
      let index = removeRoles.indexOf(e.target.name);
      if (index !== -1) {
        // Verwijder deze
        removeRoles.splice(index, 1);
        setNewUser({
          ...newUser,
          roles: removeRoles,
        });
      }
    }
  };

  useEffect(() => {
    let validUsername;
    let validEmail;
    let validPassword;
    let validRoles;

    if (newUser.username.length >= 3) {
      validUsername = true;
    } else {
      validUsername = false;
    }

    if (
      newUser.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      validEmail = true;
    } else {
      validEmail = false;
    }

    if (newUser.password.length > 6) {
      validPassword = true;
    } else {
      validPassword = false;
    }

    if (newUser.roles.length > 0) {
      validRoles = true;
    } else {
      validRoles = false;
    }

    if (
      validUsername === true &&
      validEmail === true &&
      validPassword === true &&
      validRoles === true &&
      validName == true
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [newUser, validName]);

  return (
    <div className="create-user">
      <Button
        className="create-user-button"
        variant="primary"
        onClick={handleShow}
      >
        Add user
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body className="show-grid">
            <Form.Row>
              <Form.Label>Username</Form.Label>
              <Form.Control
                placeholder="Enter Username"
                type="text"
                name="username"
                onBlur={validateUsername}
                value={newUser.username}
                onChange={inputHandler}
                className={validName ? "" : "input-field-invalid"}
              />
              {!validName && (
                <p className="text-invalid">
                  <small> This username is already taken.</small>
                </p>
              )}
            </Form.Row>
            <Form.Row>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={newUser.email}
                placeholder="Enter email"
                onChange={inputHandler}
              />
            </Form.Row>
            <Form.Row>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={newUser.password}
                placeholder="Enter password"
                onChange={inputHandler}
              />
            </Form.Row>
            {roles.map((role) => {
              return (
                <Form.Row key={role._id}>
                  <Form.Label className="checkbox-label-width">
                    {role.name}
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    name={role.name}
                    onChange={roleHandler}
                  />
                </Form.Row>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

            {!validForm ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <span className="d-inline-block">
                  <Button
                    variant="primary"
                    style={!validForm ? { pointerEvents: "none" } : null}
                    disabled={!validForm}
                    onClick={saveUser}
                  >
                    Save User
                  </Button>
                </span>
              </OverlayTrigger>
            ) : (
              <Button
                variant="primary"
                disabled={!validForm}
                onClick={saveUser}
              >
                Save Customer
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateUserModal;

CreateUserModal.propTypes = {
  auth: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  newUser: PropTypes.object.isRequired,
  setNewUser: PropTypes.func.isRequired,
  validName: PropTypes.bool.isRequired,
};
