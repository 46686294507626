export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const DELETE_COURSE_OPTIMISTIC = "DELETE_COURSE_OPTIMISTIC";
export const LOAD_DEVICES_SUCCESS = "LOAD_DEVICES_SUCCESS";
export const LOAD_ENDPOINTS_SUCCESS = "LOAD_ENDPOINTS_SUCCESS";

export const LOAD_CUSTOMERS_SUCCESS = "LOAD_CUSTOMERS_SUCCESS";

export const LOAD_DASHBOARD_SUCCESS = "LOAD_DASHBOARD_SUCCESS";

export const LOAD_DEVICE_DETAILS_SUCCESS = "LOAD_DEVICE_DETAILS_SUCCESS";

export const LOAD_DEVICE_ENDPOINTS_SUCCESS = "LOAD_DEVICE_ENDPOINTS_SUCCESS";

export const LOAD_DEVICE_STATE_SUCCESS = "LOAD_DEVICE_STATE_SUCCESS";

export const LOAD_DEVICE_MODEM_REQUESTS_SUCCESS =
  "LOAD_DEVICE_MODEM_REQUESTS_SUCCESS";

export const LOAD_DEVICE_RAW_MESSAGES_SUCCESS =
  "LOAD_DEVICE_RAW_MESSAGES_SUCCESS";

export const LOAD_ALL_RAW_MESSAGES_SUCCESS = "LOAD_ALL_RAW_MESSAGES_SUCCESS";

export const LOAD_LOGS_SUCCESS = "LOAD_LOGS_SUCCESS";

export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_ROLES_SUCCESS = "LOAD_ROLES_SUCCESS";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";

export const LOAD_CUSTOMER_DETAILS_SUCCESS = "LOAD_CUSTOMER_DETAILS_SUCCESS";
export const LOAD_CUSTOMER_DEVICES_SUCCESS = "LOAD_CUSTOMER_DEVICES_SUCCESS";
export const LOAD_CUSTOMER_USERS_SUCCESS = "LOAD_CUSTOMER_USERS_SUCCESS";
export const SAVE_CUSTOMER_SUCCESS = "SAVE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const REMOVE_CUSTOMER_SUCCESS = "REMOVE_CUSTOMER_SUCCESS";

export const LOAD_DEVICE_CUSTOMER_HISTORY_SUCCESS =
  "LOAD_DEVICE_CUSTOMER_HISTORY_SUCCESS";

export const SAVE_CUSTOMER_DEVICES_SUCCESS = "SAVE_CUSTOMER_DEVICES_SUCCESS";
export const REMOVE_CUSTOMER_DEVICES_SUCCESS =
  "REMOVE_CUSTOMER_DEVICES_SUCCESS";

export const INVALID_USER_SIGNUP_NAME = "INVALID_USER_SIGNUP_NAME";
export const VALID_USER_SIGNUP_NAME = "VALID_USER_SIGNUP_NAME";
export const INVALID_CUSTOMER_SIGNUP_NAME = "INVALID_CUSTOMER_SIGNUP_NAME";
export const VALID_CUSTOMER_SIGNUP_NAME = "VALID_CUSTOMER_SIGNUP_NAME";
export const INVALID_CUSTOMER_SIGNUP_EMAIL = "INVALID_CUSTOMER_SIGNUP_EMAIL";
export const VALID_CUSTOMER_SIGNUP_EMAIL = "VALID_CUSTOMER_SIGNUP_EMAIL";

export const SAVE_CONFIGLIST_SUCCESS = "SAVE_CONFIGLIST_SUCCESS";

export const LOAD_CONFIG_TEMPLATES_SUCCESS = "LOAD_CONFIG_TEMPLATES_SUCCESS";
export const SAVE_CONFIG_TEMPLATE_SUCCESS = "SAVE_CONFIG_TEMPLATE_SUCCESS";
export const REMOVE_CONFIG_TEMPLATE_SUCCESS = "REMOVE_CONFIG_TEMPLATE_SUCCESS";
