import * as types from "./actionsTypes";
import * as RawMessagesApi from "../api/RawmessageApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export const loadAllRawMessages = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  RawMessagesApi.getRawmessages(auth)
    .then((messages) => {
      dispatch({
        type: types.LOAD_ALL_RAW_MESSAGES_SUCCESS,
        payload: {
          rawMessages: messages,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
