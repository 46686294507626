import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const rawMessagesReducer = (state = initialState.rawMessages, action) => {
  switch (action.type) {
    case types.LOAD_ALL_RAW_MESSAGES_SUCCESS:
      return action.payload.rawMessages;
    default:
      return state;
  }
};

export default rawMessagesReducer;
