import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/customers/";

export const getCustomerById = (auth, id) => {
  return fetch(baseUrl + id, {
    headers: { "x-access-token": auth.user.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
};

function getAllCustomers(auth) {
  return fetch(baseUrl, {
    headers: { "x-access-token": auth.user.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

function getResellerCustomers(auth, reseller) {
  return fetch(baseUrl + "/reseller/" + reseller, {
    headers: { "x-access-token": auth.user.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCustomers(auth) {
  if (auth.user.customerId === null) {
    return getAllCustomers(auth);
  } else {
    if (auth.user.roles.includes("ROLE_RESELLER")) {
      let reseller = auth.user.customerId;
      return getResellerCustomers(auth, reseller);
    } else {
      return;
    }
  }
}

export const saveCustomer = (customer, auth) => {
  return fetch(baseUrl, {
    method: customer.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
    body: JSON.stringify(customer),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const removeCustomer = (auth, id) => {
  var test = { _id: id };
  return fetch(process.env.API_URL + "/api/customers", {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
    body: JSON.stringify(test),
  })
    .then(handleResponse)
    .catch(handleError);
};

export function checkValidCustomerName(auth, customer) {
  var test = { name: customer };
  return fetch(process.env.API_URL + "/api/customers/name", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
    body: JSON.stringify(test),
  }).then(handleResponse);
}

export function checkValidCustomerEmail(auth, customer) {
  var test = { email: customer };
  return fetch(process.env.API_URL + "/api/customers/email", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
    body: JSON.stringify(test),
  }).then(handleResponse);
}
