import { handleResponse, handleError } from "./apiUtils";

export const saveDevicesToCustomer = (auth, devices) => {
  return fetch(process.env.API_URL + "/api/customers/devices", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
    body: JSON.stringify(devices),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const removeDevicesFromCustomer = (auth, devices) => {
  return fetch(process.env.API_URL + "/api/customers/devices", {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
    body: JSON.stringify(devices),
  })
    .then(handleResponse)
    .catch(handleError);
};
