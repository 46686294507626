import * as types from "./actionsTypes";
import * as deviceModemRequestApi from "../api/deviceModemRequestApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const loadModemRequests = (auth, IMEI) => async (dispatch) => {
  dispatch(beginApiCall());

  deviceModemRequestApi
    .getmodemrequestsforDevices(auth, IMEI)
    .then((requests) => {
      dispatch({
        type: types.LOAD_DEVICE_MODEM_REQUESTS_SUCCESS,
        payload: {
          modemRequests: requests,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const saveModemRequest = (auth, request) => async (dispatch) => {
  deviceModemRequestApi
    .savemodemrequestsforDevices(auth, request)
    .then((res) => {
      toast.success("The following command has been sent: " + res.Command);
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const deleteModemRequest = (auth, request) => async (dispatch) => {
  deviceModemRequestApi
    .delmodemrequestsforDevices(auth, request)
    .then((res) => {
      toast.warning("The following command has been removed: " + res.Command);
      return res;
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
