import * as types from "./actionsTypes";
import * as UserApi from "../api/frontendusersApi";
import * as CustomerApi from "../api/customerApi";

export const checkValidUser = (auth, username) => async (dispatch) => {
  UserApi.checkValidUser(auth, username)
    .then(() => {
      dispatch({
        type: types.VALID_USER_SIGNUP_NAME,
        payload: {
          validName: true,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: types.INVALID_USER_SIGNUP_NAME,
        payload: {
          validName: false,
        },
      });
    });
};

export const checkValidCustomerName = (auth, customer) => async (dispatch) => {
  CustomerApi.checkValidCustomerName(auth, customer)
    .then(() => {
      dispatch({
        type: types.VALID_CUSTOMER_SIGNUP_NAME,
        payload: {
          validCustomerName: true,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: types.INVALID_CUSTOMER_SIGNUP_NAME,
        payload: {
          validCustomerName: false,
        },
      });
    });
};

export const checkValidCustomerEmail = (auth, customer) => async (dispatch) => {
  CustomerApi.checkValidCustomerEmail(auth, customer)
    .then(() => {
      dispatch({
        type: types.VALID_CUSTOMER_SIGNUP_EMAIL,
        payload: {
          validCustomerEmail: true,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: types.INVALID_CUSTOMER_SIGNUP_EMAIL,
        payload: {
          validCustomerEmail: false,
        },
      });
    });
};
