import React, { useEffect, useState } from "react";
import ConfigurationModal from "./ConfigurationModal";
import PropTypes from "prop-types";
import moment from "moment";
import { saveConfigTemplate } from "../../../actions/configTemplateActions";

// Redux
import { useDispatch, connect } from "react-redux";

// Components
import ConfigurationTable from "./ConfigurationTable";

const ConfigurationTab = ({ auth, customer }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(0);
  const [progress, setProgress] = useState(0);
  const [configuration, setConfiguration] = useState({});

  useEffect(() => {
    // dispatch(loadConfigTemplates(auth, customer));
    setBaseConfig();
  }, []);

  const setBaseConfig = () => {
    setConfiguration({
      name: "",
      createdAt: moment().format("YYYY-MM-DD"),
      createdBy: auth.user.id,
      customerId: customer.customer._id,
      BooterVersion: "5",
      CAN1Version: "5",
      ConfigurationVersion: "5",
      CAN2Version: "5",
      AddonBoardVersion: "5",
      ProtocolCode: "5",
      CANControlerConfig: {
        Status: "5",
        CAN1baudrate: "5",
        CAN1mode: "5",
        CAN2baudrate: "5",
        CAN2mode: "5",
        Speedcalibrationvalue: "5",
        Fuelcalibrationvalue: "5",
        PTOstatus: "5",
      },
      ThresholdConfig: {
        Harshbrake: "5",
        Longidle: "5",
        HighAcceleration: "5",
        OverSpeed: "5",
        HighRevolutions: "5",
        HighTorque: "5",
        HighAccelerationPedal: "5",
        MediumBrake: "5",
        MediumIdle: "5",
        MediumAcceleration: "5",
        MediumSpeed: "5",
        MediumRevolutions: "5",
        MediumTorque: "5",
        MediumAccelerationPedal: "5",
        LowBrake: "5",
        LowIdle: "5",
        LowAcceleration: "5",
        LowSpeed: "5",
        LowRevolutions: "5",
        LowTorque: "5",
        LowAccelerationPedal: "5",
        StopSpeed: "5",
        AccelerationPedalSpeed: "5",
        OverspeedTime: "5",
        HighRpmTime: "5",
        CoastingTime: "5",
      },
      SettingsConfig: {
        iButton: "1",
        PrivateSwitchEnable: "1",
        D8Enable: "1",
        TachographType: "0",
        FutureBool1: "5",
        RTStandbyInterval: 1,
        RTDatatimeInterval: 1,
        RTDatadistanceInterval: 1,
        RCDateTimeInterval: 1,
        RCDataDistanceInterval: 1,
        RCDataTriggerMask: 1,
        TDDataTimeInterval: 1,
        TDDataDistanceInterval: 1,
        STDDataTriggerMask: 1,
        iButtonClearInterval: 1,
        FutureInt1: 1,
        FutureInt2: 1,
      },
    });
  };

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setPage(0);
    setProgress(0);
    setBaseConfig();
  };

  const saveConfigurationHandler = () => {
    dispatch(saveConfigTemplate(auth, configuration));
    handleClose();
  };

  return (
    <div>
      <ConfigurationModal
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        page={page}
        setPage={setPage}
        progress={progress}
        setProgress={setProgress}
        configuration={configuration}
        setConfiguration={setConfiguration}
        saveConfigurationHandler={saveConfigurationHandler}
      />
      <br />
      <br />
      <ConfigurationTable
        auth={auth}
        configTemplates={customer.configTemplates}
      />
      <i>Configs only support settings at this time.</i>
    </div>
  );
};

ConfigurationTab.propTypes = {
  auth: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customer: state.customerDetails,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationTab);
