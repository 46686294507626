import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/configtemplate/";

export function loadConfigTemplates(auth, customer) {
  return fetch(baseUrl + customer, {
    headers: { "x-access-token": auth.user.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveConfigTemplate(auth, config) {
  return fetch(baseUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
    body: JSON.stringify(config),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function removeConfigTemplate(auth, id) {
  return fetch(baseUrl + id, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
