import { useState, useEffect } from "react";

export const SortedTable = (list) => {
  const [sortConfig, setSortConfig] = useState(null);
  const [sortedList, setSortedList] = useState([]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    let sortedList = list;
    if (sortConfig !== null) {
      if (sortConfig.key === "Rawmessage") {
        sortedList.sort((a, b) => {
          const [firstA] = a[sortConfig.key];
          const [firstB] = b[sortConfig.key];
          if (firstA.Timestamp < firstB.Timestamp) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (firstA.Timestamp > firstB.Timestamp) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      if (sortConfig.key === "Configs") {
        sortedList.sort((a, b) => {
          const [firstA] = a[sortConfig.key];
          const [firstB] = b[sortConfig.key];
          if (firstA.DeviceinSync < firstB.DeviceinSync) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (firstA.DeviceinSync > firstB.DeviceinSync) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      } else {
        sortedList.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
    }
    setSortedList(sortedList);
  }, [list, sortConfig]);

  return { list: sortedList, requestSort, sortConfig };
};

export default SortedTable;
