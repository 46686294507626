import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import Confirmation from "../../../components/Confirmation";

import { removeConfigTemplate } from "../../../actions/configTemplateActions";
import { SortedTable } from "../../common/SortedTable";
// Redux
import { useDispatch } from "react-redux";

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

const ConfigurationTable = ({ auth, configTemplates }) => {
  const dispatch = useDispatch();
  const { list } = SortedTable(configTemplates);
  const [selectedConfig, setSelectedConfig] = useState(null);

  const selectConfig = (e) => {
    setSelectedConfig(
      configTemplates.find((config) => {
        return config._id == e;
      })
    );
  };

  const removeConfigTemplateHandler = (config) => {
    dispatch(removeConfigTemplate(auth, config._id));
  };

  return (
    <div className="customer">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>_id</th>
            <th>name</th>
          </tr>
        </thead>
        <tbody>
          {list.length === 0 ? (
            <tr>
              <td colSpan="13" style={{ textAlign: "center" }}>
                No configurations found
              </td>
            </tr>
          ) : (
            list.map((config) => {
              return (
                <ContextMenuTrigger
                  attributes={{
                    className: "table-row",
                  }}
                  id="table-row-right-click-menu"
                  uri={config._id}
                  collect={(props) => props}
                  renderTag="tr"
                  key={config._id}
                >
                  <td>{config._id}</td>
                  <td>{config.name}</td>
                </ContextMenuTrigger>
              );
            })
          )}
        </tbody>
      </table>
      <ContextMenu
        id="table-row-right-click-menu"
        collect={(props) => props}
        onShow={(e) => selectConfig(e.detail.data.uri)}
      >
        <MenuItem>
          <Confirmation
            buttonText="Remove template"
            description={`Are you sure you want to remove this template?`}
            onConfirm={() => removeConfigTemplateHandler(selectedConfig)}
          />
        </MenuItem>
        <MenuItem>Cancel</MenuItem>
      </ContextMenu>
    </div>
  );
};

ConfigurationTable.propTypes = {
  auth: PropTypes.object.isRequired,
  configTemplates: PropTypes.array.isRequired,
};

export default ConfigurationTable;
