import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/logs/";

export function getlogs(auth, level = "") {
    return fetch(baseUrl + level, { headers: { 'x-access-token': auth.user.accessToken } })
        .then(handleResponse)
        .catch(handleError);
}



