import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/rawmessages/";

export function getRawmessages(auth) {
    return fetch(baseUrl, { headers: { 'x-access-token': auth.user.accessToken } })
        .then(handleResponse)
        .catch(handleError);
}



