import React from "react";
import PropTypes from "prop-types";

const EndpointList = ({ endpoints }) => (
  <>
    <table className="table table-striped table-hover">
      <thead className="thead-inverse">
        <tr>
          <th>Name</th>
          <th>IP</th>
          <th>Port</th>
          <th>Username</th>
          <th>Password</th>
          <th>API_Key</th>
          <th>Authrization</th>
          <th>Status</th>
          <th>Devices</th>
        </tr>
      </thead>
      <tbody>
        {endpoints.length === 0 ? (
          <tr>
            <td colSpan="12" style={{ textAlign: "center" }}>
              No endpoints found
            </td>
          </tr>
        ) : (
          endpoints.map((endpoint) => {
            return (
              <tr className="table-row" key={endpoint._id}>
                <td>{endpoint.Name}</td>
                <td>{endpoint.OutputServer}</td>
                <td>{endpoint.OutputPort}</td>
                <td>{endpoint.Username}</td>
                <td>{endpoint.Password}</td>
                <td>{endpoint.API_KEY}</td>
                <td>{endpoint.Authorization}</td>
                <td>{endpoint.Status}</td>
                <td>{endpoint.amountdevices}</td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  </>
);

EndpointList.propTypes = {
  endpoints: PropTypes.array.isRequired,
};

export default EndpointList;
