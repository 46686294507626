import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// Redux
import { useDispatch } from "react-redux";

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

// Components
import Confirmation from "../../components/Confirmation";
import UpdateUserModal from "./UpdateUserModal";
import UpdateUserForCustomerModal from "./UpdateUserForCustomerModal";
import { updateNewUser } from "../../actions/userActions";

const UserTable = ({ customer, auth, users, roles, removeUserHandler }) => {
  //useEffect(() => {}, [users, roles]);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const selectUser = (e) => {
    setSelectedUser(
      users.find((user) => {
        return user._id == e;
      })
    );
  };

  useEffect(() => {
    if (selectedUser != null) {
      var roleList = selectedUser.roles.map((role) => {
        return role.name;
      });
      setUpdatedUser({
        id: selectedUser._id,
        username: selectedUser.username,
        email: selectedUser.email,
        password: "",
        passwordConf: "",
        roles: roleList,
      });
    }
  }, [selectedUser]);

  const [updatedUser, setUpdatedUser] = useState({
    id: "",
    username: "",
    email: "",
    password: "",
    passwordConf: "",
    roles: [],
  });

  const updateUserHandler = () => {
    if (updatedUser.password == "") {
      delete updatedUser.password;
      delete updatedUser.passwordConf;
    }
    dispatch(updateNewUser(auth, updatedUser));
    setUpdatedUser({
      id: "",
      username: "",
      email: "",
      password: "",
      passwordConf: "",
      roles: [],
    });
    setShow(false);
  };

  return (
    <>
      <table className="table table-striped table-hover">
        <thead className="thead-inverse">
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Roles</th>
          </tr>
        </thead>
        <tbody>
          {users.length === 0 ? (
            <tr>
              <td colSpan="4" style={{ textAlign: "center" }}>
                No users found
              </td>
            </tr>
          ) : (
            users.map((user) => {
              return (
                <ContextMenuTrigger
                  attributes={{
                    className: "table-row",
                  }}
                  id="table-row-right-click-menu users"
                  uri={user._id}
                  collect={(props) => props}
                  renderTag="tr"
                  key={user._id}
                >
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.roles.map((role, i) => {
                      return (
                        <p style={{ display: "inline" }} key={role._id}>
                          {role.name.toString().charAt(0).toUpperCase()}
                          {role.name.toString().slice(1)}
                          {i + 1 < user.roles.length ? ", " : ""}
                        </p>
                      );
                    })}
                  </td>
                </ContextMenuTrigger>
              );
            })
          )}
        </tbody>
      </table>
      <ContextMenu
        id="table-row-right-click-menu users"
        collect={(props) => props}
        onShow={(e) => selectUser(e.detail.data.uri)}
      >
        <MenuItem>
          {customer && selectedUser != null && (
            <UpdateUserForCustomerModal
              show={show}
              setShow={setShow}
              current={selectedUser}
              updatedUser={updatedUser}
              setUpdatedUser={setUpdatedUser}
              roles={roles}
              updateUserHandler={updateUserHandler}
            />
          )}
          {!customer && selectedUser != null && (
            <UpdateUserModal
              show={show}
              setShow={setShow}
              current={selectedUser}
              updatedUser={updatedUser}
              setUpdatedUser={setUpdatedUser}
              roles={roles}
              updateUserHandler={updateUserHandler}
            />
          )}
        </MenuItem>
        <MenuItem>
          <Confirmation
            buttonText="Remove user"
            description={`Are you sure you want to remove this user?`}
            onConfirm={() => removeUserHandler(selectedUser)}
          />
        </MenuItem>
        <MenuItem>Cancel</MenuItem>
      </ContextMenu>
    </>
  );
};

UserTable.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  customer: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
  removeUserHandler: PropTypes.func.isRequired,
};

export default UserTable;
