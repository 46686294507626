import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { connect, useDispatch } from "react-redux";
import {
  loadDevices,
  getSingleDeviceByIMEI,
} from "../../actions/deviceActions";

// Components
import Spinner from "../common/Spinner";
import DeviceNavigation from "./DeviceNavigation";

import "./DeviceDetails.css";

const ManageDeviceDetailsPage = ({ auth, devices, device, ...props }) => {
  const dispatch = useDispatch();

  // On mount; check if devices are loaded
  useEffect(() => {
    if (devices.length == 0) {
      dispatch(loadDevices(auth));
    }
  }, [devices]);

  // On mount; use param to fetch device
  useEffect(() => {
    const id = props.match.params.id;
    if (devices.length > 0) {
      const findDevice = devices.find((device) => device._id === id);
      dispatch(getSingleDeviceByIMEI(auth, findDevice.IMEI));
    }
  }, [devices]);

  return (
    <>
      {devices.length === 0 || device == undefined ? (
        <Spinner />
      ) : (
        <>
          <DeviceNavigation {...props} />
        </>
      )}
    </>
  );
};

ManageDeviceDetailsPage.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object,
  devices: PropTypes.array.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    devices: state.devices,
    device: state.deviceDetails.device,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageDeviceDetailsPage);
