import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

// Redux
import { useDispatch, connect } from "react-redux";
import { loadDashboard } from "../../actions/dashboardActions";

// Components
import Dashboard from "./components/Dashboard";

const HomePage = ({ auth, apiCallsInProgress, dashboard }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadDashboard(auth));
  }, []);

  return (
    <div className="jumbotron">
      <h1>TelematiQs Middleware </h1>
      <p>QAN-All Middleware Configuration Portal V 0.1.0</p>
      {auth.isLoggedIn === true ? (
        <>
          <Dashboard
            dashboard={dashboard}
            apiCallsInProgress={apiCallsInProgress}
          />
        </>
      ) : (
        <Redirect to="/Login" />
      )}
    </div>
  );
};

HomePage.propTypes = {
  auth: PropTypes.object.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
  dashboard: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    apiCallsInProgress: state.apiCallsInProgress,
    dashboard: state.dashboard,
  };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
