import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/configlist/";

export function saveConfigList(auth, IMEI, request) {
  return fetch(baseUrl + IMEI, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
    body: JSON.stringify(request),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveConfigTemplateToDevice(auth, device, templateId) {
  return fetch(process.env.API_URL + "/api/configtemplate/" + templateId, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
    body: JSON.stringify(device),
  })
    .then(handleResponse)
    .catch(handleError);
}
