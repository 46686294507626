import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { connect, useDispatch } from "react-redux";
import { loadEndpoints } from "../../actions/endpointActions";

// Components
import EndpointList from "./EndpointList";
import Spinner from "../common/Spinner";

// Styling
import { Card, Form, Button } from "react-bootstrap";

const EndpointPage = ({ auth, endpoints }) => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const [filter, setFilter] = useState("Name");

  const filterHandler = (e) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    dispatch(loadEndpoints(auth));
  }, []);

  const refreshData = () => {
    dispatch(loadEndpoints(auth));
  };

  useEffect(() => {
    const result = endpoints.filter((endpoints) =>
      endpoints[filter].toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    setSearchResult(result);
  }, [searchTerm, endpoints, filter]);

  return endpoints.length === 0 ? (
    <Spinner />
  ) : (
    <>
      <h2 className="title">Endpoints </h2>
      <div className="endpoints">
        <Card className="search">
          <div className="search">
            <Form.Control
              className="search-select"
              as="select"
              name="customer"
              value={filter}
              onChange={filterHandler}
            >
              <option key={"Name"} value={"Name"}>
                Name
              </option>
              <option key={"OutputServer"} value={"OutputServer"}>
                IP
              </option>
              <option key={"OutputPort"} value={"OutputPort"}>
                Port
              </option>
            </Form.Control>
            <input
              className="search-input"
              type="text"
              placeholder="Search endpoints"
              value={searchTerm}
              onChange={handleChange}
            />
            <div className="result">
              <b>Results : </b>
              {searchResult.length > 0
                ? " " + searchResult.length
                : " " + endpoints.length}
            </div>
            <Button
              className="refresh-border"
              variant="light"
              onClick={refreshData}
            >
              ⟳
            </Button>
          </div>
        </Card>
      </div>
      <br />
      <Card>
        {searchTerm.length === 0 ? (
          <EndpointList endpoints={endpoints} />
        ) : (
          <EndpointList endpoints={searchResult} />
        )}
      </Card>
    </>
  );
};

EndpointPage.propTypes = {
  auth: PropTypes.object.isRequired,
  endpoints: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    endpoints: state.endpoints,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EndpointPage);
