import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/rawmessages/";

export function getrawmessagesforDevices(auth, IMEI) {
    return fetch(baseUrl + IMEI, { headers: { 'x-access-token': auth.user.accessToken } })
        .then(handleResponse)
        .catch(handleError);
}



