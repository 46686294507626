import React from "react";

import { Card } from "react-bootstrap";

const CanConfPage = () => {
  return (
    <div className="can-conf">
      <h2 className="title">CAN-CONF</h2>
      <Card>
        <Card.Header>CAN-CONF</Card.Header>
        <Card.Body>This page is currently under construction.</Card.Body>
        <Card.Footer>See you soon!</Card.Footer>
      </Card>
    </div>
  );
};

export default CanConfPage;
