import * as types from "./actionsTypes";
import * as customerDevicesApi from "../api/deviceCustomerApi";
import * as deviceApi from "../api/deviceApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

import { loadDevices } from "./deviceActions";

export const getDevicesByCustomerId = (auth, id) => async (dispatch) => {
  dispatch(beginApiCall());

  dispatch({
    type: "LOADING_CUSTOMER_DETAILS",
    payload: {
      isLoading: true,
    },
  });

  deviceApi
    .loadDevicesByCustomer(auth, id)
    .then((devices) => {
      dispatch({
        type: types.LOAD_CUSTOMER_DEVICES_SUCCESS,
        payload: {
          devices: devices,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const getDeviceCustomerHistory = (auth, IMEI) => async (dispatch) => {
  deviceApi
    .getDeviceCustomerHistory(auth, IMEI)
    .then((customerHistory) => {
      dispatch({
        type: types.LOAD_DEVICE_CUSTOMER_HISTORY_SUCCESS,
        payload: {
          customerHistory: customerHistory,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const saveDevicesToCustomer = (auth, devices) => async (dispatch) => {
  customerDevicesApi
    .saveDevicesToCustomer(auth, devices)
    .then((res) => {
      // Response returns an array of the relations if new customer, array of strings by removed customer
      if (typeof res[0] === "string") {
        toast.warning(
          res.length + " device(s) succesfully removed from customer"
        );
      } else {
        toast.success(res.length + " device(s) succesfully added to customer");
      }
      // So we need to do another API call for complete new device list for the customer
      dispatch(loadDevices(auth));
    })

    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const removeDevicesFromCustomer =
  (auth, devices) => async (dispatch) => {
    customerDevicesApi
      .removeDevicesFromCustomer(auth, devices)
      .then((res) => {
        // Remove response array from current devices in state
        toast.error(res.length + " devices removed succesfully");
        // So we need to do another API call for complete new device list for the customer
        dispatch(loadDevices(auth));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
