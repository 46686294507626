import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/endpoints/";

export function getEndpointsforDevice(auth, IMEI) {
  return fetch(baseUrl + IMEI, {
    headers: { "x-access-token": auth.user.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}
