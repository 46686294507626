import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/devices/";

export function loadDevicesByCustomer(auth, id) {
  return fetch(baseUrl + "customer/" + id, {
    headers: {
      "x-access-token": auth.user.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export const getDeviceCustomerHistory = (auth, IMEI) => {
  return fetch(process.env.API_URL + "/api/customers/devices/" + IMEI, {
    method: "GET",
    headers: {
      "x-access-token": auth.user.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
};

export function getDevices(auth) {
  if (auth.user.customerId === null) {
    return getAllDevices(auth);
  } else {
    if (auth.user.roles.includes("ROLE_RESELLER")) {
      let reseller = auth.user.customerId;
      return getResellerDevices(auth, reseller);
    } else {
      let customer = auth.user.customerId;
      return getCustomerDevices(auth, customer);
    }
  }
}

function getCustomerDevices(auth, customer) {
  return fetch(baseUrl + "customer/" + customer, {
    headers: {
      "x-access-token": auth.user.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

function getResellerDevices(auth, reseller) {
  return fetch(baseUrl + "reseller/" + reseller, {
    headers: {
      "x-access-token": auth.user.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

function getAllDevices(auth) {
  return fetch(baseUrl, {
    headers: {
      "x-access-token": auth.user.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getSingleDevice(auth, IMEI) {
  return fetch(baseUrl + IMEI, {
    headers: {
      "x-access-token": auth.user.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveDevice(course) {
  return fetch(baseUrl + (course.id || ""), {
    method: course.id ? "PUT" : "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(course),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveDeviceSettings(auth, device) {
  return fetch(process.env.API_URL + "/api/configlist/" + (device._id || ""), {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "x-access-token": auth.user.accessToken,
    },
    body: JSON.stringify(device),
  })
    .then(handleResponse)
    .catch(handleError);
}
